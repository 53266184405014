import './sourceDetails.scss';
import { useParams } from 'react-router-dom';
import { errors as e } from 'shared/errors';
import { useEffect, useRef, useState } from 'react';
import { sourceDetailsLayout as layout } from 'layouts/Sources/SourceDetailsLayout';
import { text as t } from 'shared/text';
import useComponents from 'components/hooks/Components/useComponents';
import useInputs from 'components/hooks/Inputs/useInputs';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Card from 'app-components/Cards/Card/Card';
import Dropdown from 'components/Dropdown/Dropdown';
import Input from 'components/Input/Input';
import Page from 'app-components/Page/Page';
import ConfirmModal from 'modals/FlowModals/ConfirmModal/ConfirmModal';

const SourceDetails = ({ newSource, newSubmit, newCancel }) => {
    const { id } = useParams();
    const { navTo } = useComponents();
    const { updateInputData, changeInputErrors, 
            validateForm, reNestedData } = useInputs();
    const { addModal } = useModal();
    const { setHeader } = useProfile();
    const { callAPI, hasRank, updateNotice } = useUtils();
    const [ pageData, setPageData ] = useState();
    const [ deviceTypes, setDeviceTypes ] = useState();
    const [ environments, setEnvironments ] = useState();
    const [ sourceTypes, setSourceTypes ] = useState();
    const [ masked, setMasked ] = useState();
    const runOnce = useRef(false);
    const setOnce = useRef(false);
    const permitted = hasRank('admin');

    useEffect(() => {
        if (runOnce.current) {return};
        runOnce.current = true;
        const params = newSource ? {updateSourceId: -1} : {updateSourceId: id};
        callAPI('sourceDetails', sourceDetailsCallback, params, params);
        callAPI('deviceTypes', deviceTypesCallback);
        callAPI('environments', environmentsCallback);
        callAPI('sourceTypes', sourceTypesCallback);
    }, []);

    useEffect(() => {
        if (runOnce.current && pageData?.id === id) {return};
        if (pageData && deviceTypes && environments && sourceTypes && !setOnce.current) {
            setOnce.current = true;
            const dropdownList = {
                terminalTypeId: {list: 0},
                environmentNameId: {list: 1},
                updateSourceType: {list: 2, nullable: t.none}
            }
            if (newSource) {
                pageData.id = -1;
            }
            const update = updateInputData(pageData, dropdownList, [deviceTypes, environments, sourceTypes]);
            update && setMasked(false);
        }
    }, [pageData, deviceTypes, environments, sourceTypes])

    const sourceDetailsCallback = (data, props, accessToken) => {
        if (data?.isSuccessful) {
            const details = data?.tableData?.[0];
            setPageData(details);
            !newSource && setHeader(details?.name ? details.name: '');
        } else {
            setPageData({});
        }
    }

    const deviceTypesCallback = (data) => {
        setDeviceTypes(data?.kioskTypes ? data.kioskTypes : []);
    };

    const environmentsCallback = (data) => {
        setEnvironments(data?.tableData ? data.tableData: []);
    }

    const sourceTypesCallback = (data) => {
        setSourceTypes(data?.tableData ? data.tableData : []);
    }

    const onSubmit = (event, setLoading) => {
        const errorsList = validateForm(layout.validators);
        const params = { record: reNestedData(pageData)};
        params.record.updateSourceType = 0;
        const props = {event: event, setLoading: setLoading}
        if (newSource) {params.record.id = 0};
        if (errorsList) {
            updateNotice('formErrors', props);
            changeInputErrors(errorsList);
        } else {
            setLoading(true);
            callAPI('sourceUpdate', sourceUpdateCallback, params, props); 
        }
    }

    const sourceUpdateCallback = (data, props) => {
        newSource ? newSubmit(data) : updateNotice(data, props);
    }

    const onCancel = () => {
        newCancel();
    };

    const onDelete = (event, setLoading) => {
        const props = { event: event, setLoading: setLoading };
        setLoading(true)
        addModal(<ConfirmModal 
            header={t.warning} 
            onConfirm={() => {callAPI('sourceDelete', sourceDeleteCallback, {sourceId: parseInt(id)}, props)}}
            content={t.warningForDeleteSource}
        />);
    }

    const sourceDeleteCallback = (data, props) => {
        props.setLoading(false);
        if (data?.isSuccessful) {
            updateNotice(data);
            navTo('/sources', ['replace'] )
        } else {
            data.errorMessage = e.manifestsOnSource,
            updateNotice(data, props);
        }
    }

    return (
        <Page contentClasses='grid'>
            <Card classes='full' hideLabel={true}>
                <Input classes='half display-only' line='id' masked={masked}
                    label={t.id} noPermission={!permitted}/>
                <Input line='name' classes='half' masked={masked}
                    label={t.name} noPermission={!permitted}/>
                {/* <Dropdown id='updateSourceType' classes='half' data={sourceTypes} masked={masked} nullable={t.none}
                    label={t.sourceType} noPermission={!permitted}/> 
                    Comment: removed b/c only option is ftp, which has a value of 0, which means it should be treated as a null value
                    This doesn't make sense, so just hardcoding it to 0 until a change is needed*/} 
                <Input classes='half' line='url' masked={masked} maxLength={'none'} hideCover={true}
                    label={t.url} data={pageData} noPermission={!permitted}/>
                <Input classes='half' line='filename' masked={masked}                 
                    label={t.fileName} noPermission={!permitted} maxLength={100}/>
                <Dropdown id='environmentNameId' classes='half' data={environments} masked={masked}
                    label={t.environment} noPermission={!permitted}/>
                <Dropdown id='terminalTypeId' classes='half' data={deviceTypes} masked={masked}
                    label={t.deviceType} noPermission={!permitted}/>
            </Card>
            {newSource && <div className='full modal-buttons-container grid'>
                <Button 
                    classes='red quarter'
                    onClick={onCancel}
                    >{t.cancel}
                </Button>
                <Button 
                    classes='green quarter last' 
                    type='submit' 
                    onClick={(event, setLoading)=>onSubmit(event, setLoading)}
                >{t.submit}</Button>
            </div>}
            {!newSource && <div className='half last grid'>
                <Button 
                    classes='red half' 
                    type='submit' 
                    onClick={(event, setLoading)=>onDelete(event, setLoading)}
                >{t.delete}</Button>
                <Button 
                    classes='green half' 
                    type='submit' 
                    onClick={(event, setLoading)=>onSubmit(event, setLoading)}
                >{t.submit}</Button>
            </div>}
        </Page>
    )
}

export default SourceDetails;
