import { createContext, useState } from 'react';

const InputsContext = createContext({});

export const InputsProvider = ({ children }) => {
    const [ currentInput, setCurrentInput ] = useState('');
    const [ inputValues, setInputValues ] = useState({});
    const [ inputErrors, setInputErrors ] = useState({});
    const [ dropdownOriginals, setDropdownOriginals ] = useState({});
    const [ dropdownDisplays, setDropdownDisplays ] = useState({});
    const [ touchscreen, setTouchscreen ] = useState(false);
    const [ touchScreenState, setTouchScreenState ] = useState();
    const [ sliderClosed, setSliderClosed ] = useState(true);
    
    return (
        <InputsContext.Provider value={{
            currentInput, setCurrentInput,
            inputValues, setInputValues,
            inputErrors, setInputErrors,
            dropdownOriginals, setDropdownOriginals,
            dropdownDisplays, setDropdownDisplays,
            touchscreen, setTouchscreen,
            touchScreenState, setTouchScreenState,
            sliderClosed, setSliderClosed,
        }}>
            {children}
        </InputsContext.Provider>
    )
}

export default InputsContext;
