import './deviceDetails.scss';

import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getFinanceData } from 'shared/utils';
import { deepCopy, getAPIDates, 
         getDefaultDateRange, updateURL } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import useInputs from 'components/hooks/Inputs/useInputs';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Card from 'app-components/Cards/Card/Card';
import CashDisplay from 'components/CashDisplay/CashDisplay';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Dropdown from 'components/Dropdown/Dropdown';
import FinanceDisplay from 'components/FinanceDisplay/FinanceDisplay';
import Input from 'components/Input/Input';
import Page from 'app-components/Page/Page';
import NoticeModal from 'modals/FlowModals/NoticeModal/NoticeModal';
import CashChart from '../CashChart/CashChart';

const DeviceDetails = () => {
    const { id } = useParams();
    const { inputValues, clearInput,
            updateNestedInputData } = useInputs();
    const { addModal } = useModal();
    const { setHeader, deviceTabs, updateDeviceBreadcrumbs } = useProfile();
    const { callAPI, callAPIGet, 
            updateDeviceTabs,
            hasRank, updateNotice } = useUtils();
    const [ pageData, setPageData ] = useState();
    const [ boxCount, setBoxCount ] = useState(0);
    const [ locationsList, setLocationsList ] = useState();
    const [ companiesList, setCompaniesList ] = useState();
    const [ statuses, setStatuses ] = useState([]);
    const [ deviceSubtypes, setDeviceSubtypes ] = useState();
    const [ masked, setMasked ] = useState([true]);
    const [ maskedFinancials, setMaskedFinancials] = useState([false]);
    const [ maskedAssign, setMaskedAssign ] = useState([false]);
    const [ maskedInfo, setMaskedInfo ] = useState([false]);
    const [ defaultDates, setDefaultDates ] = useState();
    const [ financials, setFinancials ] = useState();
    const [ rawCashData, setRawCashData ] = useState({});
    const [ cashBreakdown, setCashBreakdown ] = useState();
    const runOnce = useRef(false);
    const deviceType = useRef();
    const isRecycler = useRef();
    const noticeTimeout = useRef();
    const currentLocation = useRef();
    const selectingCompany = useRef(false);
    const dataSet = useRef(false);
    const urlId = 'device-details-' + id;
    const permitted = hasRank('owner');

    const dropdownLists = {
        companyId: {list: 0},
        ['location-id']: {list: 1, nullable: t.noLocation },
        terminalSubClassId: {list: 2, nullable: t.noDeviceSubtype, valueProp: 'terminalSubClassId', labelProp: 'description'}
    }

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
        const params = {terminalId: id}
        callAPIGet('deviceDetails', deviceDetailsCallback, params);
        return () => {
            if (noticeTimeout.current) {
                clearTimeout(noticeTimeout.current);
            }
        };
    }, []);

    useEffect(()=> {
        if (companiesList && !dataSet.current && pageData && locationsList && deviceSubtypes) {
            dataSet.current = true;
            const updated = updateNestedInputData(pageData, dropdownLists, [companiesList, locationsList, deviceSubtypes]);
            updated && setMasked([false]);
        }
    }, [pageData, companiesList, locationsList, deviceSubtypes]);

    useEffect(() => {
        if (!rawCashData) {};
        const reportedCash = deepCopy(rawCashData);
        if (isRecycler.current) {
            const slots = pageData.cassettes;
            slots.forEach((slot) => {
                const denom = slot.denom;
                if (denom === 10) {
                    reportedCash.numberOfTenDollarBills = reportedCash.numberOfTenDollarBills - slot.current_Level;
                } else {
                    reportedCash.numberOfTwentyDollarBills = reportedCash.numberOfTwentyDollarBills - slot.current_Level;
                }
            })
        }
        setCashBreakdown(reportedCash);
    }, [rawCashData, isRecycler])

    useEffect(()=>{
        if (selectingCompany.current) {
            clearInput(['location-id'], true);
            selectingCompany.current = false;
        }
    }, [inputValues.companyId])

    const deviceDetailsCallback = (data, props) => {
        if (!data?.isSuccessful) {
            setMasked([false]);
            return;
        }
        setStatuses(data.terminalStatus);
        setPageData(data);
        currentLocation.current = data?.location?.id ? data.location.id : null;
        setBoxCount(data?.cassettes?.length ? data.cassettes.length : 0);
        const type = data.terminalType;
        updateDeviceTabs(type.id)
        const typeId = type.id;
        const range = getDefaultDateRange(urlId);
        updateURL(urlId, 'dates', range);
        setDefaultDates(range);
        updateDeviceBreadcrumbs(data);
        deviceType.current = typeId;
        isRecycler.current = (typeId === 2 || typeId === 7) ? true : false;
        setHeader(type.name + ': ' + data.terminalId);
        getReportData(range);
        callAPI('deviceSubtypes', deviceSubtypesCallback, {terminalTypeId: type.id});
        if (typeId === 1) {
            setCompaniesList([]);
            setLocationsList([]);
        } else {
            callAPI('companies', companyListCallback, '', props);
            callAPI('locationsByCompany', locationsByCompanyCallback, {companyId: data.companyId, validAddress: true});
        }
    }

    const getReportData = (range, selected) => {
        selected && setMaskedFinancials([true]);
        const params = getAPIDates(range);
        params.terminalId = id;
        callAPIGet('report-DeviceGames', gamePerformanceCallback, params, selected);
    }

    const gamePerformanceCallback = (data, selected) => {
        selected && setMaskedFinancials([false]);
        if (!data?.isSuccessful) {
            setMasked([false]);
            return;
        }
        const records = data?.data ? data.data : []
        setFinancials(getFinanceData([deviceType.current], data?.financialSummary, records))
        setRawCashData(data?.cashBreakdown ? data.cashBreakdown : null);
    }

    const locationsByCompanyCallback = (data, props) => {
        const list = data?.tableData ? data.tableData : [];
        setLocationsList(list);
        if (props?.selecting) {
            const companyId = props.companyId;
            selectingCompany.current = false;
            const newData = deepCopy(inputValues);
            newData.companyId = companyId;
            newData['location-id'] = null;
            updateNestedInputData(newData, dropdownLists, [companiesList, list, deviceSubtypes]);
            selectingCompany.current = true;
        };
        setMaskedAssign([false]);
    }

    const deviceSubtypesCallback = (data, props) => {
        const list = data?.isSuccessful ? data.subClassList : [];
        setDeviceSubtypes(list);
        setMaskedInfo([false]);
        if (props?.adding) {
            updateNotice(data, {id: 'terminalSubClassId'});
        }
    }

    const companyListCallback = (data) => {
        setCompaniesList(data?.companyList ? data.companyList : []);
    }

    const onCompanySelect = (data) => {
        setMaskedAssign([true]);
        const id = data.value;
        const params = {
            companyId: id,
            validAddress: true
        }
        const props = {
            selecting: true,
            companyId: id
        }
        callAPI('locationsByCompany', locationsByCompanyCallback, params, props);
    }

    const onDateRangePick = (range) => {
        updateURL(urlId, 'dates', range);
        getReportData(range, true);
    }

    const onAssign = (event, setLoading) => {
        setLoading(true);
        setMaskedAssign([true]);
        const params = {
            terminalId: id,
            companyId: inputValues.companyId,
            locationId: inputValues['location-id']
        }
        const props = {
            event: event,
            setLoading: setLoading,
            locationId: inputValues['location-id']
        }
        noticeTimeout.current = setTimeout(() => {
            addModal(<NoticeModal header={t.assignmentInProgress} content={t.assignmentNotice}/>)
        }, 3000);
        callAPI('commissionTerminal', commissionTerminalCallback, params, props);
    }

    const commissionTerminalCallback = (data, props) => {
        props.setLoading(false);
        setMaskedAssign([false]);
        clearTimeout(noticeTimeout.current);
        updateNotice(data, props);
        if(data.isSuccessful) {currentLocation.current = props.locationId}
    }

    const onDeviceSubtypeAdd = (text) => {
        setMaskedInfo([true]);
        const params = {
            description: text,
            terminalTypeId: deviceType.current
        }
        const prop = {
            target: document.getElementById('terminalSubClassId')
        }
        callAPI('deviceSubtypeAdd', deviceSubtypeAddCallback, params, prop);
    }

    const deviceSubtypeAddCallback = (data, prop) => {
        setMaskedInfo([false]);
        if (data.isSuccessful) {
            callAPI('deviceSubtypes', deviceSubtypesCallback, {terminalTypeId: deviceType.current}, {adding: true});
        }
        updateNotice(data, prop);
    }

    const onUpdateInfo = (event, setLoading) => {
        setLoading(true);
        setMaskedInfo([true]);
        const serial = inputValues?.machineSerialNumber;
        const ipAddress = inputValues?.ipAddress3rdPartyHostServer;
        const type = inputValues?.terminalSubClassId;
        const params = {
            terminalId: id,
            machineSerialNumber: serial ? serial : '',
            ipAddress3rdPartyHostServer: ipAddress ? ipAddress : '',
            terminalSubClassId: type ? type : null
        }
        const props = {
            event: event,
            setLoading: setLoading
        }
        callAPI('deviceInfoUpdate', deviceInfoUpdateCallback, params, props)
    }

    const deviceInfoUpdateCallback = (data, props) => {
        props.setLoading(false);
        setMaskedInfo([false]);
        updateNotice(data, props);
    }

    return (
        <Page
            subPage={t.details}
            tabs={deviceTabs.tabs}
            contentClasses='grid device-details'
        >
            <Card classes='half' label={t.deviceInfo}>
                <Input classes='half display-only' line='platformVersion' label={t.firmwareVersion} masked={masked?.[0]}/>
                <Input classes='half display-only' line='mac' label={t.mac} masked={masked?.[0]}/>
                <Input classes='half display-only' line='ipAddress' label={t.ipAddress} masked={masked?.[0]}/>
                <Input
                    classes='half'
                    line='ipAddress3rdPartyHostServer'
                    label={t.thirdPartyIP}
                    placeholder={(deviceType.current === 7 || deviceType.current === 1) ? '' : t.na}
                    masked={masked?.[0] || maskedInfo?.[0]}
                />
                <Input
                    classes='half'
                    line='machineSerialNumber'
                    noPermission={!permitted}
                    placeholder={!permitted ? t.nullVal: ''}
                    masked={masked?.[0] || maskedInfo?.[0]}
                    label={t.serialNumber}
                />
                <Dropdown
                    id='terminalSubClassId'
                    classes='half'
                    masked={masked?.[0] || maskedInfo?.[0]}
                    label={t.subtype}
                    data={deviceSubtypes}
                    valueProp='terminalSubClassId'
                    nullable={t.noDeviceSubtype}
                    addCallback={onDeviceSubtypeAdd}
                />
                <Button
                    classes={`green third last`}
                    type='submit'
                    onClick={(event, setLoading)=>onUpdateInfo(event, setLoading)}
                    >{t.updateInfo}
                </Button>
            </Card>
            <div className='half'>
                <Card classes='full device-statuses-card' label={t.status}>
                    <div className='device-status-label two-thirds'>{t.commissioned}</div>
                    <div className={`device-status-result third ${statuses.includes(6) ? '' : 'success'}`}>
                        {statuses.includes(6) ? t.false : t.true}
                    </div>
                    <div className='device-status-label two-thirds'>{t.inService}</div>
                    <div className={`device-status-result third ${(statuses.includes(4) || statuses.includes(9)) ? '' : 'success'}`}>
                        {statuses.includes(4) ? t.pausedService : statuses.includes(9) ? t.pausedManually : t.true}
                    </div>
                    <div className='device-status-label two-thirds'>{t.authenticated}</div>
                    <div className={`device-status-result third ${statuses.includes(5) ? '' : 'success'}`}>
                        {statuses.includes(5) ? t.false : t.true}
                    </div>
                    <div className='device-status-label two-thirds'>{t.securityCheck}</div>
                    <div className={`device-status-result third ${statuses.includes(3) ? '' : 'success'}`}>
                        {statuses.includes(3) ? t.fail : t.pass}
                    </div>
                    <div className='device-status-label two-thirds'>{t.billAcceptorOperational}</div>
                    <div className={`device-status-result third ${statuses.includes(10) ? '' : 'success'}`}>
                        {statuses.includes(10) ? t.false : t.true}
                    </div>
                </Card>
                {deviceType.current !== 1 && <Card classes='full assignment-top' label={t.assignment}>
                    <Dropdown
                        id='companyId'
                        classes='half'
                        label={t.currentCompany}
                        data={companiesList}
                        masked={masked?.[0] || maskedAssign?.[0]}
                        updatesNext={true}
                        callback={onCompanySelect}
                    />
                    <Dropdown
                        section='location'
                        line='id'
                        classes='half'
                        label={t.currentLocation}
                        data={locationsList}
                        masked={masked?.[0] || maskedAssign?.[0]}
                        disabled={!locationsList || !inputValues.companyId}
                    />
                    <Button
                        classes='green quarter last'
                        type='submit'
                        disabled={!inputValues.companyId || currentLocation.current === inputValues['location-id']}
                        onClick={(event, setLoading)=>onAssign(event, setLoading)}
                        >{inputValues['location-id'] === null ? t.unassign : t.assign}
                    </Button>
                </Card>}
            </div>
            {deviceType === 5 ? null :<Card label={'Device Financials'} classes={'device-details-finances-card full'} masked={masked?.[0] || maskedFinancials?.[0]}>
                <DateRangePicker
                    classes={`${(boxCount > 0 || (isRecycler.current && cashBreakdown)) ? 'third' : 'quarter'}`}
                    defaultVal={defaultDates}
                    label={t.dateRange}
                    disabled={masked?.[0]}
                    callback={onDateRangePick}
                />
                <div className='two-thirds'/>
                <FinanceDisplay types={[deviceType.current]} classes= 'quarter' data={financials?.data}/>
                {!isRecycler.current && <CashDisplay title={t.cashBreakdown} data={cashBreakdown} classes='quarter'/>}
                {(boxCount > 0 || (isRecycler.current && cashBreakdown)) && <CashChart data={pageData} isRecycler={isRecycler.current}/>}
                {isRecycler.current && <CashDisplay title={t.stacker} classes='quarter' data={cashBreakdown} />}
            </Card>}
        </Page>
    )
}

export default DeviceDetails;
