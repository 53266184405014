import './tableSearchBar.scss';
import { useState, useEffect } from 'react';
import { tablesData as t } from '../../tablesData';
import Button from 'components/Button/Button';
import Icon from 'components/Icon/Icon';
import useTable from '../TableHooks/useTables';
import TableSettings from '../TableSettings/TableSettings';

const TableSearchBar = ({id, layout, searchBarContent, masked, noURL}) => {
    const { tableSearches, updateTable } = useTable();
    const [ showSettings, setShowSettings ] = useState([false]);
    const [ maskState, setMaskState ] = useState(masked ? masked : '');
    const hideSearch = layout?.hideSearch ? 'hide' : '';

    useEffect(() => {
        const onSettingsClick = (event) => {
            const container = event.target.closest('.table-settings-container')
            if (!container || container.id !== id + '-table-settings') {
                setShowSettings([false]);
            }
        }
        window.addEventListener('click', onSettingsClick);
        return () => {
            window.removeEventListener('click', onSettingsClick);
        };
    }, []);

    useEffect(() => {
        if (!masked) {
            setMaskState('fading-pseudo');
            setTimeout(() => {
                setMaskState('fading-mask');
                setTimeout(() => {
                    setMaskState('faded');
                }, 300);
            }, 100); 
        } else {
            setMaskState(false);
        }
    }, [masked]);

    const onChange = (event) => {
        updateTable('searches', id,  event.target.value, noURL);
    }

    const toggleSettings = () => {
        setShowSettings([!showSettings[0]]);
    }

    return (
        <div className={`table-search-bar ${hideSearch}`}>
            <div className='table-search-bar-content grid'>
                {searchBarContent}
            </div>
            <div className='table-search-right'>
                <div className='input-container'>
                    <div className='input-label'>{t.search}</div>
                    <div className={`input-mask ${maskState}`}/> 
                    <input
                        id={`${id}-search`}
                        className='input-display'
                        type='text'
                        maxLength={50}
                        inputMode='none'
                        autoComplete='off'
                        tabIndex={0}
                        value={tableSearches?.[id] ? tableSearches[id] : ''}
                        onChange={(event)=>{onChange(event)}}/>
                </div>
                {!layout?.hideSettings &&<Button 
                    classes='table-search-settings-button blue'
                    disabled={masked}
                    onClick={toggleSettings}
                >{<Icon icon='gear'/>}</Button>}
            </div>
            <TableSettings id={id} layout={layout} open={showSettings[0]} noURL={noURL}/>
        </div>
    )
}

export default TableSearchBar;
