import './link.scss';
import { useEffect, useState } from 'react';
import useComponents from 'components/hooks/Components/useComponents';
import Icon from '../Icon/Icon';

const Link = ({ id, route, type, navData, children, targetIdVal }) => {
    const { navTo } = useComponents();
    const [ loading ] = useState(false);
    const [ URL, setURL]  = useState();

    useEffect(() => {
        // The command prop still exists on navData - it's currently not used. 
        setURL(route);
    }, [window.location.search])
    
    const onLinkClick = (event) => {
        event.preventDefault();
        route && navTo(route);
    }

    return (
        <div className={`link-container`}>
            {loading ? <div className='link-spinner spinner'/>
            :<a  href={URL} 
                className={`link-target`} 
                onClick={(event) =>{onLinkClick(event)}}
            >{type === 'icon' ? <Icon icon={children}/> :children}</a>}
        </div>
    )
}

export default Link;