import './contextDropdown.scss';
import { getActionType } from 'shared/utils';
import { useParams } from 'react-router-dom';
import { text as t } from 'shared/text';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import DeviceActionModal from 'modals/FlowModals/DeviceActionModal/DeviceActionModal';
import MultiCheckModal from '../MultiCheckModal/MultiCheckModal';
import SetLocationModal from '../SetLocationModal/SetLocationModal';

const ContextDropdown = ({layout, menu, pageId}) => {
    const { setModal } = useModal();
    const { tableStates } = useProfile();
    const { callAPI, updateNotice } = useUtils();
    const { id } = useParams();
    const stateId = layout?.id;
    const items = layout?.contextMenu;

    const onSubmit = (event, setLoading) => {
        const target = event.currentTarget;
        const prop = target.getAttribute('prop');
        if (prop === 'executeScript' || prop === 'openSupportTunnel') {
            setModal(<DeviceActionModal pageId={pageId} prop={prop} terminalId={id}/>);
        } else if (!menu) {
            callContextFunction(event, setLoading);
        } else {
            const states = tableStates[stateId];
            const multiCheck = states?.multiCheck;
            target.closest('.context-menu-container').classList.remove('open');
            if (prop === 'setLocation') {
                setModal(<SetLocationModal pageId={pageId}/>);
            } else {
                if (!multiCheck || multiCheck.length <= 1) {
                    callContextFunction(event, setLoading);
                } else {
                    setModal(<MultiCheckModal pageId={pageId} prop={prop} type={getActionType(prop)}/>);
                }
            }
        }
    }

    const callContextFunction = (event, setLoading) => {
        const params = {
            terminalId: id ? id : tableStates[pageId].multiCheck[0],
            terminalCommandType: getActionType(event.currentTarget.getAttribute('prop'))
        };
        const props = {
            event: event,
            setLoading: setLoading
        }
        setLoading(true);
        callAPI('terminalPost', contextCallback, params, props);
    }

    const contextCallback = (data, props)=>{
        props.setLoading(false);
        menu ? updateNotice(data) : updateNotice(data, props);
    }

    return (items ? items.map((item, i) => {
        if (item === 'setLocation' && !menu) {return}
        return  <Button key={i} classes={`context-menu-button ${menu ? '' : 'third'}`}
            type='submit'
            prop={item}
            onClick={(event, setLoading)=>onSubmit(event, setLoading)}
        >
            {t[item]}
        </Button>
    }) : <></>)
}

export default ContextDropdown;
