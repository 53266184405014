import { text as t } from 'shared/text';

export const deviceKioskLayout = {    
    defaultSort:  {
        prop: 'id',
        desc: true,
    },

    tableHeaders: {
        id: t.transactionId,
        createdOn: t.createdOn,
        transactionType: t.transactionType,
        cashIn: t.cashIn,
        cashOut: t.cashOut,
        net: t.netCash
    },

    sizes: {
        id: 'mid',
        createdOn: 'large',
        transactionType: 'mid',
        cashIn: 'mid',
        cashOut: 'mid',
        net: 'mid'
    },

    layouts: {
        id: 'text-left',
        createdOn: 'text-left',
        transactionType: 'text-left',
        cashIn: 'blank text-right',
        cashOut: 'blank text-right',
        net: 'blank text-right'
    },

    formats: {
        createdOn: 'standard-date',
        transactionType: 'transactionType',
        cashIn: 'currency',
        cashOut: 'currency',
        net: 'currency'
    },

    hideSortArrows: {
        net: true
    },
    
    subtotals: {
        groupBy: 'all',
        fields: {
            id: 'header',
            cashIn: 'total',
            cashOut: 'total',
            net: {
                first: {column:'cashIn', type: 'total'},
                 calc: 'add',
                 second: {column:'cashOut', type: 'total'}
             },
        },
        layouts: {
            id: 'text-left',
            cashIn: 'text-right',
            cashOut: 'text-right',
            net: 'text-right'
        },
        formats: {
            cashIn: 'currency',
            cashOut: 'currency',
            net: 'currency'
        }
    }
}
