import './tableBody.scss';
import { isThen } from 'components/shared/componentUtils';
import { tablesData as t } from '../../tablesData';
import useTable from '../TableHooks/useTables';
import TableCell from '../TableCell/TableCell';
import TableSubtotalSection from '../TableSubtotalSection/TableSubtotalSection';

const TableBody = ({id, layout, masked, noURL, callback}) => {
    const { tableData, tableSelectedRows, getTableColumns } = useTable();
    const overflowEnabled = layout?.overflowEnabled;
    const bodyId = id + '-table-body';
    const columns = getTableColumns(id, layout);

    const buildRows = () => {
        const data = tableData?.[id];
        if (!data || data.length <= 0) {return <div className='table-row empty'>{t.noResults}</div>}
        if (!layout) {return};
        let rows = data;
        const length = rows.length;
        let result = [];
        for (let i=0; i<length; i++) {
            if (rows[i].subtitle) {
                let endIndex;
                const sectionRows = [];
                for (let j=i + 1; j<rows.length; j++) {
                    if (rows[j].subtotals) {
                        endIndex = j;
                        break;
                    } else {
                        sectionRows.push(rows[j])
                    }
                }
                result.push(<TableSubtotalSection key={`subtotal-section-${i}`} id={id} layout={layout} rows={rows} sectionRows={sectionRows} startIndex={i} endIndex={endIndex} columns={columns} buildRow={buildRow}/>);
                i = endIndex;
            } else {
                result.push(buildRow(i, rows[i], length));
            }
        }
        return result;
    }

    const buildRow = (i, row, length) => {
        const rowId = id + '-row-' + i;
        const noBottom = (i + 1 === length && length >= 10);
        const allColumns = getTableColumns(id, layout);
        return (<div id={rowId} key={rowId} index={i} 
            className={`table-row ${tableSelectedRows?.[id] === rowId.toString() ? 'selected' : ''} ${noBottom ? 'no-bottom' : ''}`}
        >   
            {allColumns.map((prop, j) => {
                return buildCells(layout, row, prop , j, i, rowId);
            })}
        </div>)
    }
    
    const buildCells = (layout, rowData, prop, columnIndex, rowIndex, rowId) => {
        const header = layout.tableHeaders[prop];
        const appends = layout.appends;
        const content = rowData[prop];
        const appendProp = appends?.[prop];
        if (Array.isArray(header)) {
            const list = [];
            header.forEach((val, k) => {
                const itemValue = content[k];
                list.push(<TableCell 
                    id={id} 
                    layout={layout}
                    key={`${rowId}-${prop}-${k}`} 
                    index={rowIndex}
                    rowId={rowId}
                    prop={prop}
                    content={itemValue}
                    appendData={appendProp ? {
                        prop: appendProp, 
                        content: rowData[appendProp]
                    } : null}
                    callback={callback}
                />)
            })
            return list;
        } else {
            return <TableCell 
                id={id}
                layout={layout} 
                key={rowId + columnIndex} 
                index={rowIndex} 
                rowId={rowId}
                prop={prop} 
                content={content}
                appendData={appendProp ? {
                    prop: appendProp, 
                    content: rowData[appendProp]
                } : null}
                tooltip={layout?.tooltips?.[prop]}
                callback={callback}
            />
        }
    }

    return (
        <div id={bodyId} className='table-body' style={overflowEnabled}>
            <div className={`table-loading-mask ${isThen(!masked, 'hide')}`}/>
            {buildRows()}
        </div>
    )
}

export default TableBody;
