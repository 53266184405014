import React from 'react';
import { isValid, toCurrency, 
         toCurrencyDecimal, toCurrencyWhole,
         toPhone, toStandardDate, 
         toSimpleDate } from 'components/shared/componentUtils';
import { tablesData as t } from './tablesData';

export const addCellClasses = (prop, layout, content, key) => {
    if (!layout) {return}
    const sizes = layout.sizes;
    const format = layout.formats?.[prop];
    const hide = layout.hideColumns;
    const tooltips = layout.tooltips;
    let classes = layout.layouts?.[prop] ? layout.layouts[prop] : '';
    if (content === t.nullVal) { classes += (' placeholder') };
    if (prop === 'checkbox') {classes += ' check-all'};
    if (isNumberFormat(format) && typeof content === 'string' && content.includes('-')) {classes += (' cell-negative')};
    if (sizes?.[prop]) { classes += ' ' + sizes[prop] };
    if (hide?.[prop]) { classes += (' hide-cell-' + hide[prop]) }
    if (tooltips?.[prop]) { classes += (' tooltip-cell') }
    return classes;
}

export const buildSpacer = (size, type, key) => {
    return <div key={key} className={`${type === 'header' ? 'table-header-cell' : 'table-cell space'} ${size}`}/>
}

export const clearSelected = (id) => {
    const old = document.getElementById(id)?.getElementsByClassName('selected')?.[0];
    if (old) { old.classList.remove('selected') }
}

export const formatContent = (content, prop, layout, empty) => {
    const layouts = layout.layouts;
    const countryCode = layout.countryCode;
    if (layouts[prop] && layouts[prop].includes('blank') && !isValid(content)) { return ''}
    if (!content && layout?.customNulls?.[prop] ) {
        return layout.customNulls[prop];
    }
    if (!isValid(content)) { return empty ? '' : t.nullVal };
    const formats = layout.formats;
    if (typeof content === 'boolean') {
        content = content ? 'true' : 'false';
    }
    const type = formats?.[prop];
    const length = content?.length;
    switch (type) {
        case 'billType':
            return '$' + content;
        case 'currency':
            return toCurrency(content, countryCode);
        case 'currency-decimal':
            return toCurrencyDecimal(content, countryCode);
        case 'currency-whole':
            return toCurrencyWhole(content, countryCode);
        case 'indicator':
            if (content === 'true'){
                return <div className='table-indicator on'></div>
            } else {
                return <div className='table-indicator'></div>
            }
        case 'lastFour':
            return content ? '**' + content.slice(length - 4, length) : t.nullVal;
        case 'number':
            return content.toLocaleString('en-US');
        case 'one-in-x':
            return !content.toFixed ? content : '1 in ' + content.toFixed(2);
        case 'percentage':
            return isValid(content) && content.toFixed ? content.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%': '';
        case 'percentage-whole':
            return isValid(content) && content.toFixed ? content.toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '%': '';
        case 'percentage-decimal':
            return isValid(content) && content.toFixed ? (content * 100).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%': '';
        case 'percentage-decimal-whole':
            return isValid(content) && content.toFixed ? (content * 100).toLocaleString('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }) + '%': '';
        case 'phone':
            return content ? toPhone(content) : t.nullVal;
        case 'standard-date':
            return toStandardDate(content);
        case 'simple-date':
            return toSimpleDate(content);
        default:
            return (showNullVal(content) && !empty) ? t.nullVal : content
    }
}

export const getSubtitleContent = (columns, subtotals, current) => {
    const newRow = {};
    const groupBy = subtotals.groupBy;
    {columns.map((column) => {
        newRow[column] = column === groupBy ? (current ? current : t.na) : ''
    })}
    return newRow;
}

export const getSubtotalContent = (columns, subtotals, rows) => {
    const newRow = {};
    if (!subtotals) {return ''}
    {columns.map((column) => {
        if (!rows.length) {return ''}
        const resultType = subtotals.fields[column];
        if (resultType === 'header') {
            newRow[column] = t.results;
        } else if (resultType === 'none') {
            newRow[column] = '';
        } else {
            newRow[column] = resultType ? getSubtotal(column, resultType, rows) : '';
        } 
    })}
    return newRow;
}

export const getSubtotal = (column, resultType, data) => {
    if (typeof resultType !== 'string') { // custom subtotals come in as objects: others as strings
        return getCustomSubtotal(resultType, data);
    } else {
        switch (resultType) {
            case 'average':
                return getSubtotalAverage(column, data);
            case 'max':
                return getSubtotalMax(column, data)
            default:
                return getSubtotalSum(column, data);
        }
    }
}

const getCustomSubtotal = (field, data) => {
    const first = field.first;
    const second = field.second;
    const firstVal = getSubtotal(first.column, first.type , data);
    const secondVal = getSubtotal(second.column, second.type, data);
    switch (field.calc) {
        case 'divide':
            return secondVal === 0 ? t.na : firstVal / secondVal;
        case 'multiply':
            return firstVal * secondVal;
        case 'add':
            return firstVal + secondVal;
        case 'subtract': 
            return firstVal - secondVal;
        default:
            return secondVal === 0 ? t.na : firstVal / secondVal;
    }
}

const getSubtotalAverage = (column, data) => {
    let total = 0;
    let count = 0;
    data.forEach((row) => {
        const content = row[column]; 
        if (content) {
            total+= row[column]
            count ++;
        }
    })
    return count === 0 ? t.na : total/count;
}

const getSubtotalMax = (column, data) => {
    let max = null;
    data.forEach((row) => {
        const content = row[column]; 
        if (content && (max === null || content > max)) {
            max = content;
        }
    });
    return max === null ? 0 : max;
}

export const getSubtotalSum = (column, data) => {
    if (!data.length) {return 0}
    let total = 0;
    data.forEach((row) => {
        const content = row[column]; 
        if (content) {
            total+= row[column]
        }
    })
    return total;
};

const isNumberFormat = (format) => {
    if (format === 'currency' || format === 'currency-decimal' || format === 'currency-whole') {
        return true;
    } else {
        return false;
    }
}

export const showTextWithLineBreaks = (value) => {
    if (value.includes('<br/>')) {
        return value.split('<br/>').map((line, index, array) => (
            <React.Fragment key={index}> {line}{index < array.length - 1 && <br />}</React.Fragment>
        ));
    } else {
        return value
    }
}

export const showNullVal = (value) => {
    return (value === '' || value === null || value === undefined) ? true : false;
}