export const sourceDetailsLayout = {
    id: 'source-details',

    validators: [
        {
            id: 'name',
            checks: ['required']
        },
        {
            id: 'filename',
            checks: ['required']
        }
    ]
}