import './kioskPerformanceReport.scss';
import { useEffect, useState } from 'react';
import { kioskPerformanceReportLayout as layout } from 'layouts/Reports/KioskPerformanceReportLayout';
import { deepCopy, getDefaultDateRange, getAPIDates } from 'components/shared/componentUtils';
import * as e from 'shared/excel';
import { text as t } from 'shared/text';
import useInputs from 'components/hooks/Inputs/useInputs';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Dropdown from 'components/Dropdown/Dropdown';
import TableListed from 'components/Tables/TableListed';
const tableId = 'kioskPerformanceReport';
const companyId = tableId + '-companyId';

const KioskPerformanceReport = () => {
    const { updateDropdown } = useInputs();
    const { tableData, tableTotals, tableDropdowns,
            getURLParams, getTableColumns, 
            updateTable, updateDropdowns } = useTables();
    const { callAPI, callAPIGet } = useUtils();
    const [ companies, setCompanies ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ tableRecords, setTableRecords ] = useState();
    const [ currentRange, setCurrentRange ] = useState();
    const [ masked, setMasked ] = useState([true]);

    useEffect(() => {
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        setCurrentRange(range);
        getReportData(range);
        callAPI('companies', companiesCallback);
    }, []);

    useEffect(() => {
        const urlParams = getURLParams(tableId);
        if (urlParams.dropdowns) {
            const sorts = urlParams.dropdowns?.[companyId];
            sorts && updateDropdown(companyId, sorts.value, sorts.label);
        }
    }, [companies]);

    const onExportToExcel = () => { 
        const dateInfo = {
            type: 'table',
            columns: ['startDate', 'endDate'],
            headers: {
                selector: t.companyName,
                startDate: t.startDate,
                endDate: t.endDate,
            },
            data:[{
                startDate: e.getExcelDate(currentRange[0]),
                endDate: e.getExcelDate(currentRange[1]),
            }],
            configs: {
                formats: {selector: 'text-wrap'}
            }
        };
        if (tableDropdowns?.[tableId]?.[companyId]) {
            dateInfo.data[0].selector = tableDropdowns[tableId][companyId].label,
            dateInfo.columns.splice(0,0, 'selector');
        }

        const tableRowData = deepCopy(tableData[tableId]);
        if (layout.subtotals){
            tableRowData.push({totals: tableTotals[tableId]});
        }
        const columns = getTableColumns(tableId, layout);
        const tableInfo = e.getExcelTable(t.kioskPerformance, columns, tableRowData, layout);
        const sizes = columns.map(key => layout.sizes[key]);
        e.exportToFile('Kiosks Report', [dateInfo, tableInfo], sizes);
    }

    const getReportData = (range) => {
        setMasked([true]);
        updateTable('dates', tableId, range);
        const params = getAPIDates(range);
        callAPIGet('report-KioskPerformance', kioskPerformanceReportCallback, params);
    }
    
    const kioskPerformanceReportCallback = (data) => {
        setTableRecords(data?.data ? data.data : []);
        setMasked([false]);
    }

    const companiesCallback = (data) => {
        setCompanies(data?.companyList ? data.companyList : []);
    }

    const onCompanySelect = (data) => {
        data.searchData = {
            dropdownProp: 'label',
            recordProp: 'companyName'
        }
        updateDropdowns(tableId, companyId, data);
    }

    const onDateRangePick = (range) => {
        setCurrentRange(range);
        getReportData(range);
    }

    return (
            <TableListed
                id={tableId}
                data={tableRecords}
                layout={layout}
                masked={masked?.[0]}
                searchBarContent={<>
                    <Dropdown
                        id={companyId}
                        classes='quarter'
                        label={t.companyName}
                        hideErrors={true}
                        masked={masked?.[0]}
                        data={companies}
                        nullable={t.all}
                        callback={onCompanySelect}
                    />
                    <DateRangePicker
                        id='dateRange'
                        classes='quarter'
                        label={t.dateRange} 
                        disabled={masked?.[0]}
                        defaultVal={defaultDates}  
                        callback={onDateRangePick}
                    />
                    <Button 
                        classes='green sixth match-labeled-input'
                        disabled={masked?.[0]}
                        onClick={onExportToExcel}
                    >{t.exportToExcel}</Button>
                </>}
            />
    )
}

export default KioskPerformanceReport;
