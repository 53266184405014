export const componentText = {
    all: 'All',
    clearAll: 'Clear All',
    delete: 'Delete',   
    errorEmail: 'Enter a valid email',
    errorMin: 'Must be greater than ',
    errorMinLength1: 'Must be ',
    errorMinLength2: ' characters long',
    errorOptionExists: 'Name already exists',
    errorPhone: 'Enter a valid phone number',
    errorRequired: 'Required',
    errorSelectRole: 'Select a role',
    errorZip: 'Enter a valid zip',
    na: 'N/A',
    noDropdownMatch: '- no matching option found -',
    noPermission: 'You do not have permission to view this page.',
    noResults: '- no results -',
    noSelection: 'No Selection',
    nullVal:'-',
    printFail: 'Print Failed',
    printSucess: 'Print Successful',
    results: 'Results',
    search: 'Search',
    selectOne: 'Select One',
    updateFail: 'Update Failed',
    updateSuccess: 'Update Successful'
}

const cut = ['x', 'X',]
const paste = [ 'v', 'V'];
const ctrl = ['c', 'C',  'y', 'Y', 'x', 'X', 'v', 'V', 'a', 'A', 'y', 'Y', 'c', 'C', 'z', 'Z',];
const clear = ['Backspace', 'Delete'];
const nonPrint = ['Control', 'Alt', 'Meta', 'Shift'];
const numExceptions = ['e', '-', '.'];
const directions = ['ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];

export const keydownSets = {
    numExceptions: numExceptions,
    directions: directions,
    cut: cut,
    ctrl: ctrl,
    paste: paste,
    clear: clear,
    nonPrint: nonPrint,
    skipList: ['Unidentified', 'Tab', 'Shift', 'Alt', 'Meta', 'Control', ...clear, ...directions],
}