import './cashChart.scss'
import { toCurrency } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';

const CashChart = ({data, isRecycler}) => {
    const slots = data?.cassettes ? data.cassettes : [];

    return (
        <div className='cash-chart-container grid half'>
            <div className='cash-chart-label full'>{isRecycler ? t.recycler : t.cassettes}</div>
            <div className={`cash-chart-display full`}>
                {slots.map((slot, i)=> {
                    const count = slot.current_Level;
                    const max = isRecycler ? 100 : slot.standard_Fill;
                    const value = slot.denom;
                    return (<div key={i} className={`cash-chart-column`}>
                        <div className='cash-chart-denom'>{toCurrency(value)}</div>
                        <div className='cash-chart-bar-container'>
                            <div className='cash-chart-bar-display'>{toCurrency(value * count)}</div>
                            <div className='cash-chart-bar' style={{height: `${Math.min(count / max, 1) * 100}%`}}></div>
                        </div>
                        <div className='cash-chart-details'>
                            <div className='cash-chart-values'>{`${count} / ${max}`}</div>
                        </div>
                    </div>)
                })}
            </div>
        </div> 
    )
}

export default CashChart;
