import './dateTimePicker.scss';
import { useEffect, useState } from 'react';
import { isValid } from 'components/shared/componentUtils';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateTimePicker = ({classes, hideLabel, label, defaultVal, callback}) => {
    const [selectedDate, setSelectedDate] = useState(null);
  
    useEffect(()=>{
      const date = (isValid(defaultVal) && defaultVal !== '0001-01-01T00:00:00') ? new Date(defaultVal) : null;
      setSelectedDate(date);
      callback(date);
    }, [])

    const onDateTimeSelect = (date) => {
      if (!date) {return};
      setSelectedDate(date);
      callback && callback(date.toISOString());
    };
  
    return (
      <div className={`date-time-picker ${classes ? classes : '' }`}  >
      <div className={`date-time-label ${hideLabel ? 'hide-label' : ''}`}>{label}</div>

        <ReactDatePicker
          selected={selectedDate}
          onChange={(date) => onDateTimeSelect(date)}
          timeInputLabel="Time:"
          dateFormat="MM/dd/yyyy h:mm aa"
          showTimeInput
        />
      </div>
    );
  };
  
  export default DateTimePicker;