import axios from 'axios';
import Card from 'app-components/Cards/Card/Card';
import Page from 'app-components/Page/Page';
import useModal from 'components/hooks/Modal/useModal';
import useUtils from 'hooks/useUtils';
import { batchAssignmentLayout as layout } from 'layouts/Devices/BatchAssignmentLayout';
import NoticeModal from 'modals/FlowModals/NoticeModal/NoticeModal';
import Button from 'components/Button/Button';
import Dropdown from 'components/Dropdown/Dropdown';
import Input from 'components/Input/Input';
import useInputs from 'components/hooks/Inputs/useInputs';
import 'pages/Devices/BatchAssignment/batchAssignment.scss';
import { useEffect, useRef, useState } from 'react';
import { ArrowLeftSquare, ArrowRightSquare } from 'react-bootstrap-icons';
import { text as t } from 'shared/text';

const BatchAssignment = () => {
    const { callAPI, getURL, getBaseHeaders, callAPIGet } = useUtils();
    const { inputValues, updateInput } = useInputs();
    const { addModal } = useModal();
    const { clearInput } = useInputs();
    const [companies, setCompanies] = useState([]);
    const [companyMasked, setCompanyMasked] = useState(true);
    const [sourceLocationMask, setSourceLocationMask] = useState(false);
    const [destinationLocationMask, setDestinationLocationMask] = useState(false);

    const [sourceSelectedCompanyId, setSourceSelectedCompanyId] = useState();
    const [destinationSelectedCompanyId, setDestinationSelectedCompanyId] = useState();

    const [sourceLocations, setSourceLocations] = useState([]);
    const [destinationLocations, setDestinationLocations] = useState([]);

    const [sourceSelectedLocationId, setSourceSelectedLocationId] = useState();
    const [destinationSelectedLocationId, setDestinationSelectedLocationId] = useState();

    const [devices, setDevices] = useState([]);
    const [source, setSource] = useState([]);
    const [destination, setDestination] = useState([]);

    const [loading, setLoading] = useState(false);
    const runOnce = useRef(false);
    const sourceDeviceSearchId = 'batch-assignment-source-device-search';
    const destinationDeviceSearchId = 'batch-assignment-destination-device-search';

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
        const params = {};
        callAPI('companies', companiesCallback, params);
    }, []);


    const companiesCallback = (data) => {
        setCompanyMasked(false);
        setCompanies(data?.companyList ?? []);
    };

    useEffect(() => {
        setSourceLocationMask(true);
        if (!sourceSelectedCompanyId) return;
        const params = { companyId: sourceSelectedCompanyId };
        callAPIGet('locationsGetByCompanyId', sourceCompanyLocationsCallback, params);
    }, [sourceSelectedCompanyId]);

    useEffect(() => {
        setDestinationLocationMask(true);
        if (!destinationSelectedCompanyId) return;
        const params = { companyId: destinationSelectedCompanyId };
        callAPIGet('locationsGetByCompanyId', destinationCompanyLocationsCallback, params);
    }, [destinationSelectedCompanyId]);


    useEffect(() => {
        getDevices();
    }, [sourceSelectedLocationId]);

    const getDevices = () => {
        if (!sourceSelectedLocationId) return;
        const params = { locationId: sourceSelectedLocationId };
        setLoading(true);
        callAPI('locationDevices', sourceLocationDevicesCallback, params);
    };


    const sourceCompanyLocationsCallback = (data) => {
        setSourceLocationMask(false);
        setSourceLocations(data.locations ?? []);
        setDestination([]);
    };

    const destinationCompanyLocationsCallback = (data) => {
        setDestinationLocationMask(false);
        setDestinationLocations(data.locations ?? []);
    };

    const sourceLocationDevicesCallback = (data) => {
        setLoading(false);
        setDevices(data.data ?? []);
        setSource(data?.data ? data.data : []);
    };

    const onSourceCompanySelect = (data) => {
        updateInput(sourceDeviceSearchId, '');
        clearInput('batch-assignment-source-location', true);
        setSourceSelectedCompanyId(data.value);
    };

    const onDestinationCompanySelect = (data) => {
        updateInput(destinationDeviceSearchId, '');
        clearInput('batch-assignment-destination-location', true);
        setDestinationSelectedCompanyId(data.value);
    };

    const onSourceLocationSelect = (data) => {
        updateInput(sourceDeviceSearchId, '');
        if (data.value === destinationSelectedLocationId) {
            addModal(<NoticeModal header={t.warning} onConfirm={() => {
                clearInput('batch-assignment-source-location', true);
                setSourceSelectedLocationId(null);
            }}
                                  content={t.warningForSameLocation} />);
            return;
        }
        setSourceSelectedLocationId(data.value);
    };

    const onDestinationLocationSelect = (data) => {
        updateInput(destinationDeviceSearchId, '');
        if (data.value === sourceSelectedLocationId) {
            addModal(<NoticeModal header={t.warning} onConfirm={() => {
                clearInput('batch-assignment-destination-location', true);
                setDestinationSelectedLocationId(null);
            }}
                                  content={t.warningForSameLocation} />);
            return;
        }
        setDestinationSelectedLocationId(data.value);
    };

    const moveToDestination = (device) => {
        if (destinationSelectedLocationId === sourceSelectedLocationId) return;
        setDestination([...destination, device]);
        setSource(source.filter((item) => {
            return item.id !== device.id;
        }));
    };

    const moveAllToDestination = () => {
        if (destinationSelectedLocationId === sourceSelectedLocationId) return;
        setDestination([...devices]);
        setSource([]);
    };

    const clearDestination = () => {
        setDestination([]);
        setSource([...devices]);
    };

    const deselect = (device) => {
        setSource([...source, device]);
        setDestination(destination.filter((item) => {
            return item.id !== device.id;
        }));
    };

    const onCommission = (event, setLoading) => {
        const requests = [];
        destination.forEach((device) => {
            const params = {
                terminalId: device.id,
                companyId: destinationSelectedCompanyId,
                locationId: destinationSelectedLocationId,
            };
            requests.push(axios.post(getURL('commissionTerminal'), params, getBaseHeaders()));
        });

        Promise.all(requests).then(function(values) {
            addModal(<NoticeModal header={t.assignmentInProgress} content={t.assignmentNotice} />);
            getDevices();
            setDestination([]);
            setLoading(false);
        });
    };

    return (
      <Page
        page={layout.page}
      >
          <div className={'grid'}>
              <Card label={t.source} classes="five-twelfths first">
                  <Dropdown
                    id="batch-assignment-source-company"
                    classes="half margin-top"
                    label={t.selectCompany}
                    hideErrors={true}
                    data={companies}
                    masked={companyMasked}
                    callback={onSourceCompanySelect} />
                  <Dropdown
                    id="batch-assignment-source-location"
                    classes="half  margin-top"
                    disabled={!sourceSelectedCompanyId}
                    label={t.selectLocation}
                    masked={sourceLocationMask}
                    hideErrors={true}
                    data={sourceLocations}
                    callback={onSourceLocationSelect} />
                  <Input
                    id={sourceDeviceSearchId}
                    classes="half"
                    label={t.search}
                  />
                  <div className="bucket-container full">
                      {loading ? <span className="spinner grey" /> : source.map((device, i) => {
                          if (inputValues[sourceDeviceSearchId] && device.id.toString().toLowerCase().indexOf(inputValues[ sourceDeviceSearchId ].toLowerCase()) < 0 && device.type.toString().toLowerCase().indexOf(inputValues[ sourceDeviceSearchId ].toLowerCase()) < 0) return;
                          return <div key={i} className={`bucket-row`}>
                              <div className={``} onClick={() => moveToDestination(device)}>
                                  {device.id} - {device.type}
                              </div>
                          </div>;
                      })}

                  </div>
              </Card>

              <Card hideLabel={true} classes="sixth centered bucket-control-container">
                  <ArrowRightSquare onClick={moveAllToDestination} className={'full assignment-arrow-icon'} />
                  <ArrowLeftSquare onClick={clearDestination} className={'full assignment-arrow-icon'} />
                  <Button
                    classes="green two-thirds centered margin-top"
                    type="submit"
                    disabled={destination.length === 0 || !destinationSelectedLocationId || destinationSelectedLocationId === sourceSelectedLocationId}
                    onClick={(event, setLoading) => onCommission(event, setLoading)}
                  >{t.assign}
                  </Button>
              </Card>

              <Card label={t.destination} classes="five-twelfths last">
                  <Dropdown
                    id="batch-assignment-destination-company"
                    classes="half margin-top"
                    label={t.selectCompany}
                    hideErrors={true}
                    masked={companyMasked}
                    data={companies}
                    callback={onDestinationCompanySelect} />
                  <Dropdown
                    id="batch-assignment-destination-location"
                    classes="half margin-top"
                    disabled={!destinationSelectedCompanyId}
                    label={t.selectLocation}
                    hideErrors={true}
                    masked={destinationLocationMask}
                    data={destinationLocations}
                    callback={onDestinationLocationSelect} />
                  <Input
                    id={destinationDeviceSearchId}
                    classes="half"
                    label={t.search}
                  />
                  <div className="bucket-container full">
                      {destination.map((device, i) => {
                          if (inputValues[ destinationDeviceSearchId ] && device.id.toString().toLowerCase().indexOf(inputValues[ destinationDeviceSearchId ].toLowerCase()) < 0 && device.type.toString().toLowerCase().indexOf(inputValues[ destinationDeviceSearchId ].toLowerCase()) < 0) return;
                          return <div key={i} className={`bucket-row`}>
                              <div className={``} onClick={() => deselect(device)}>
                                  {device.id} - {device.type}
                              </div>
                          </div>;
                      })}
                  </div>
              </Card>

          </div>
      </Page>
    );
};

export default BatchAssignment;
