import './card.scss';

const Card = ({classes, label, hideLabel, children, masked}) => {
    return (
        <div className={`card ${classes ? classes : ''}`}>
            <div className={`card-label ${hideLabel ? 'hide-label' : ''}`}>{label}</div>
            <div className='card-content grid'>
                <div className={`card-loading-mask ${!masked ? 'hide' : ''}`}/>
                {children}
            </div>
        </div>
    )
}

export default Card;