export const companyDetailsLayout = {
    validators: [
        {
            id: 'companyInfo-companyName',
            checks: ['required']
        },
        {
            id: 'companyInfo-zip',
            checks: ['zip'],
        },
        {
            id: 'billingInfo-zip',
            checks: ['zip'],
        },
        {
            id: 'principalContact-phone',
            checks: ['phone'],
        },
        {
            id: 'principalContact-email',
            checks: ['email'],
        },
        {
            id: 'billingContact-phone',
            checks: ['phone'],
        },
        {
            id: 'billingContact-email',
            checks: ['email'],
        },
    ]
}
