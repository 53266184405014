import { useEffect, useRef, useState } from 'react';
import { text as t } from 'shared/text';
import useInputs from 'components/hooks/Inputs/useInputs';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/useProfile';
import Modal from 'components/Modal/Modal';
import LocationDetails from 'pages/Locations/LocationDetails/LocationDetails';
import LocationSettings from 'pages/Locations/LocationSettings/LocationSettings';

const AddLocationModal = () => {
    const { clearAllInputs } = useInputs();
    const { setModal } = useModal();
    const { setRefreshPage } = useProfile();
    const [ locationId, setLocationId ] = useState();
    const runOnce = useRef();

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
    }, []);

    const onSubmit = (data) => {
        setLocationId(data);
        setRefreshPage([true]);
    }

    const onCancel = (data) => {
        setModal(false);
        clearAllInputs();
    }
    
    const onSettingsUpdate = () => {
        setRefreshPage([true]);
    }

    const onSettingsCancel = () => {
        setRefreshPage([true]);
        setModal(false);
    }

    return (
        <Modal title={`${locationId ? t.configureSettingsOptional : t.addLocation}`} classes='add-modal'>
            {!locationId && <LocationDetails newLocation={true} newSubmit={onSubmit} newCancel={onCancel}/>}
            {locationId && <LocationSettings newLocation={locationId} newSubmit={onSettingsUpdate} newCancel={onSettingsCancel}/>}
        </Modal>
    )
}

export default AddLocationModal;
