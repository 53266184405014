import { createContext, useState } from 'react';

const ComponentsContext = createContext({});

export const ComponentsProvider = ({ children }) => {
    const [ noticeContent, setNoticeContent ] = useState();
    const [ noticeState, setNoticeState ] = useState();
    const [ noticeDefaultTop, setNoticeDefaultTop ] = useState(52); // $size-xl;
    const [ tooltipData, setTooltipData ] = useState();

    return (
        <ComponentsContext.Provider value={{
            noticeContent, setNoticeContent,
            noticeState, setNoticeState,
            noticeDefaultTop, setNoticeDefaultTop,
            tooltipData, setTooltipData
        }}>
            {children}
        </ComponentsContext.Provider>
    )
}

export default ComponentsContext;
