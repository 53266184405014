export const errors = {
    adminLoginOnly: 'Only Admins can login in at this time.',
    boxEmpty: "It looks like we've run out of money",
    companyAlreadyAssigned: 'This company has already been assigned',
    emptyDrawer: "Register can't be empty when opening",
    errorMinLength1: 'Length must be at least ',
    errorMinLength2: ' characters long',
    errorMismatch: 'The values do not match',
    errorRequired: 'This field is required',
    errorSelectRole: 'Please select a role',
    errorZip: 'Please put in a valid zip code',
    fillAllFields: 'Please fill in all fields',
    genError:'Looks like something went wrong',
    genInvalid: 'Invalid response.',
    genPrint: 'Item could not be printed.',
    idNotFound: 'Transaction ID not found. Print failed.',
    invalidCSV: 'Please select a valid CSV file',
    invalidLogin: 'Login invalid. Please try again',
    invalidState: 'Stacker state is out of sync.',
    invalidUser: "Error: You can't delete the account you're logged in as", 
    manifestsOnSource: 'All manifests must be removed from this source before it can be deleted.',
    mediaType: 'INVALID MEDIA TYPE: 415',
    minLength1: 'Entry must be ', 
    minLength2: ' characters long',
    passwordMismatch: "The passwords entered don't match.",
    phoneInvalid: 'Sorry, this phone number is not valid.',
    phoneLength: 'Phone number must be 10 numbers long',
    phoneMissing: 'This PIN does not have a phone number attached.',
    phoneRegistered: 'Phone already registered: your PIN has been sent to this number',
    pinInvalid: 'This PIN is invalid. Please try again.',
    pinLength: 'PIN must be 12 charactes long',
    pinMissing: 'PIN missing',
    pinNotFound: 'This PIN could not be found. Please try another.',
    pinUnauthenticated: 'Unable to authenticate PIN.',
    pinUnsent: 'A PIN could not be provided at this time.',
    prematureComplete: 'Complete hit before bill processed',
    printerError: "It looks like something's wrong with the printer. Please check paper and ink levels.",
    recyclerEmpty: "It looks like we've run out of money",
    roleAlreadyAssigned: 'This role has already been assigned',
    roleInvalid: 'This PIN does not have a valid role',
    roleNameTaken: 'A role with this name already exists',
    server: 'INTERNAL SERVER ERROR 500',
    stackerOut: 'Kiosk will remain locked until the stacker is inserted into the bill acceptor.',
    stateLength: 'Must be 2 characters long',
    thirdParty: 'Our network is having issues. Please try again.',
    unAuth: 'This user is not authorized',
    verifyFail: 'Unable to verify',
    verifyLength: 'Verification code must be 6 numbers long.',
}