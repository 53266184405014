import './deviceTransactions.scss';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { deviceTransactionsLayout as layout } from 'layouts/Devices/DeviceDetails/DeviceTransactionsLayout';
import { getDefaultDateRange, getAPIDates } from 'components/shared/componentUtils';
import { deepCopy } from 'components/shared/componentUtils';
import * as e from 'shared/excel';
import { text as t } from 'shared/text';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/useProfile';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Dropdown from 'components/Dropdown/Dropdown';
import TableListed from 'components/Tables/TableListed';

const DeviceTransactions = () => {
    const { id } = useParams();
    const { updateDropdown } = useInputs();
    const { setHeader, updateDeviceBreadcrumbs } = useProfile();
    const { tableData, tableTotals, tableDropdowns, 
            getTableColumns, getURLParams, 
            updateTable, updateDropdowns } = useTables();
    const { callAPIGet } = useUtils();
    const [ pageData, setPageData ] = useState();
    const [ tableRecords, setTableRecords ] = useState();
    const [ currentRange, setCurrentRange ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ showExport, setShowExport ] = useState();
    const [ transactionTypes, setTransactionTypes ] = useState();
    const [ masked, setMasked ] = useState([true]);
    const tableId = 'device-transactions-' + id;
    const typeId = tableId + '-typeId';
    const allTypes = [
        {id: 0, description: t.notSet},
        {id: 1, description: t.purchase},
        {id: 2, description: t.refund},
        {id: 3, description: t.releaseEscrow},
        {id: 4, description: t.noPurchaseNecessary},
        {id: 5, description: t.redemption},
        {id: 6, description: t.promo}
    ]

    const posTypes = [
        {id: 1, description: t.purchase},
        {id: 2, description: t.refund},
        {id: 5, description: t.redemption},
    ]

    useEffect(() => {
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        setCurrentRange(range);
        getReportData(range);
        callAPIGet('deviceDetails', deviceDetailsCallback, {terminalId: id});
    }, []);

    useEffect(() => {
        const urlParams = getURLParams(tableId);
        if (urlParams.dropdowns) {
            const sorts = urlParams.dropdowns?.[typeId];
            sorts && updateDropdown(typeId, sorts.value, sorts.label);
        }
    }, [transactionTypes]);

    const deviceDetailsCallback = (data) => {
        const type = data.terminalType;
        setPageData(data);
        setHeader(type.name + ': ' + data.terminalId);
        updateDeviceBreadcrumbs(data);
        setTransactionTypes(getTypes(type.id));
        setShowExport(type.id === 7 ? true : false);
    }

    const getTypes = (type) => {
        switch(type) {
            case 3:
                allTypes.splice(6,1);
                allTypes.splice(4,1);
                return allTypes;
            case 4: 
                return posTypes;
            default:
                return allTypes;
        }
    }

    const getReportData = (range) => {
        updateTable('dates', tableId, range);
        const params = getAPIDates(range);
        params.terminalId = id;
        callAPIGet('report-DeviceTransactions', deviceTransactionsCallback, params);
    }
    
    const deviceTransactionsCallback = (data) => {
        setTableRecords(data?.tableData ? data.tableData : []);
        setMasked([false]);
    }

    const onTransactionTypeSelect = (data) => {
        data.searchData = {
            dropdownProp: 'label',
            recordProp: 'transactionType'
        }
        updateDropdowns(tableId, typeId, data);
    }

    const onDateRangePick = (range) => {
        getReportData(range);
        setCurrentRange(range);
    }

    const onExportToExcel = () => { 
        const dateInfo = {
            type: 'table',
            columns: ['deviceId', 'startDate', 'endDate', 'locationName'],
            headers: {
                deviceId: t.deviceId,
                selector: t.transactionType,
                startDate: t.startDate,
                endDate: t.endDate,
                locationName: t.locationName,
            },
            data:[{
                deviceId: id,
                startDate: e.getExcelDate(currentRange[0]),
                endDate: e.getExcelDate(currentRange[1]),
                locationName: pageData?.location?.label
            }],
            configs: {
                formats: {locationName: 'text-wrap'}
            }
        };
        if (tableDropdowns?.[tableId]?.[typeId]) {
            dateInfo.data[0].selector = tableDropdowns[tableId][typeId].label,
            dateInfo.columns.splice(1,0, 'selector');
        }

        const tableRowData = deepCopy(tableData[tableId]);
        if (layout.subtotals){
            tableRowData.push({totals: tableTotals[tableId]});
        }
        const columns = getTableColumns(tableId, layout);
        const tableInfo = e.getExcelTable(t.transactions, columns, tableRowData, layout);
        const sizes = columns.map(key => layout.sizes[key]);
        e.exportToFile(id + ' Game Performance Report', [dateInfo, tableInfo], sizes);
    }

    return (<TableListed
        id={tableId}
        classes='full'
        data={tableRecords}
        layout={layout}
        masked={masked?.[0]}
        searchBarContent={<>
            <Dropdown
                id={typeId}
                classes='quarter'
                label={t.transactionTypes}
                data={transactionTypes}
                masked={masked?.[0]}
                labelProp='description'
                nullable={true}
                hideErrors={true}
                callback={onTransactionTypeSelect}/>
            <DateRangePicker
                classes='quarter'
                defaultVal={defaultDates}
                label={t.dateRange}
                disabled={masked?.[0]}
                callback={onDateRangePick}
            />
            {showExport &&<Button 
                classes='green sixth match-labeled-input'
                disabled={masked?.[0]}
                onClick={onExportToExcel}
            >{t.exportToExcel}</Button>}
        </>}
    />)
}

export default DeviceTransactions;
