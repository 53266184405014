import { text as t } from 'shared/text'

export const locations = {
    id: 'locations',
    page: t.locations,

    tableHeaders: {
        name: t.name,
        id: t.id,
        city: t.city,
        stateCode: t.state,
        companyName: t.company,
        collector: t.assignedUser,
    },

    sizes: {
        name: 'large',
        id: 'small',
        city: 'small',
        stateCode: 'mid',
        companyName: 'mid',
        collector: 'mid',
    },

    layouts: {
        name: 'text-left',
        id: 'text-left',
        city: 'text-left',
        stateCode: 'text-left',
        companyName: 'text-left',
        collector: 'text-left',
    },

    navigation: {
        name: {
            root: 'location',
            label: 'name',
            value: 'id',
        },
        collector: {
            root: 'user',
            label: 'collector.label',
            value: 'collector.id',
        },
        companyName: {
            root: 'company',
            label: 'companyName',
            value: 'companyId'
        }
    },

    defaultSort: {
        prop: 'id',
        desc: true
    },

    rowCount: 20,
}
