import { useRef, useEffect} from 'react';
import { createPortal } from 'react-dom';
import useModal from './useModal';

const ModalPortal = () => {
    const { modal, modalTimer } = useModal();
    const timeout = useRef(null); 

    useEffect(() => {
        if (modalTimer) {
            timeout.current = setTimeout(() => {
            }, 500);
        } else {
            clearTimeout(timeout.current);
        }
        return () => clearTimeout(timeout.current) 
    }, [modalTimer]);

    if (modal) {
        return createPortal (
            <div>
                {modal}
            </div>,
            document.querySelector('#modal')
        );
    }
};

export default ModalPortal;