import './setLocationModal.scss';
import { useEffect, useRef, useState } from 'react';
import { text as t } from 'shared/text';
import useModal from 'components/hooks/Modal/useModal';
import useProfile from 'hooks/useProfile';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Dropdown from 'components/Dropdown/Dropdown';
import Icon from 'components/Icon/Icon';
import Modal from 'components/Modal/Modal';

const SetLocationModal = (props) => {
    const { setModal } = useModal();
    const { tableStates } = useProfile();
    const { callAPI } = useUtils();
    const [ currentLocation, setCurrentLocation ] = useState('');
    const [ locationsList, setLocationsList ] = useState();
    const [ selected, setSelected ] = useState();
    const [ running, setRunning ] = useState();
    const runOnce = useRef();
    const runList = [];

    useEffect(()=>{
        if (runOnce.current) {return}
        runOnce.current = true;
        callAPI('locationsByCompany', locationsByCompanyCallback, {validAddress: true});
        const states = tableStates[props.pageId]
        let allSelected = [];
        if (states.multiCheck) {allSelected.push(...states.multiCheck)};
        if (states.selected) {allSelected.push(states.selected)};
        setSelected(allSelected);
    }, []);

    const locationsByCompanyCallback = (data) => {
        setLocationsList(data?.tableData ? data.tableData : []);
    }

    const onLocationSelect = (update) => {
        setCurrentLocation(update.value);
    }

    const onSubmitClick = () => {
        setRunning(true);
        clearClasses();
        const length = selected.length
        for (let i=0; i<length; i++) {
            const item = selected[i];
            const params = {
                terminalId: +item,
                trueRouteLocationIdString: currentLocation.toString()
            }
            callAPI('deviceLocationUpdate', deviceLocationUpdateCallback, params, item);
        }
    }

    const deviceLocationUpdateCallback = (data, id) => {
        const classList = document.getElementById(id + '-state-icon').classList;
        if (data.isSuccessful) {
            classList.add('success');
        } else {
            classList.add('fail');
        }
        runList.push(id);
        if(selected.length === runList.length) {
            setRunning(false);
        }   
    }

    const onCloseClick = () => {
        setModal(false);
    }

    const clearClasses = () => {
        const length = selected.length;
        for (let i=0; i<length; i++) {
            document.getElementById(selected[i] + '-state-icon').classList.remove('success', 'fail');
        };
    }

    return (
        <Modal classes='set-location-modal' title={t.setLocationsModalTitle} contentClasses='grid'>
            <div className='location-list-container two-thirds centered'>
                {selected && selected.map((item, i) => {
                    return <div key={i} className='list-row'>
                        <div className='list-state-icon' id={`${item}-state-icon`} >
                            {Icon('check')}
                        </div>
                        <div className='list-label'>{item}</div>
                    </div>
                })}
            </div>
            <Dropdown 
                id='set-location-modal-current-location'
                classes='two-thirds centered' 
                label={t.locations} 
                data={locationsList} 
                callback={onLocationSelect}
            />
            <Button disabled={running} loading={running} classes='half green' onClick={(event, setLoading) => onSubmitClick(event, setLoading)}>{t.submit}</Button>
            <Button disabled={running} loading={running} type='submit' classes='half red' onClick={onCloseClick}>{t.close}</Button>
        </Modal>
    )
}

export default SetLocationModal;
