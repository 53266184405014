import { text as t } from 'shared/text';

export const locationDetailsLayout = {
    serviceTypes: {
        ManuallyCollected: t.manualCollect,
        Pos: t.pointOfSales,
        PrimePay: t.primePay,
        SweepsKiosk: t.sweepsKiosk,
    },

    validators: [
        {
            id: 'locationName',
            checks: ['required'],
            minLength: 3
        },
        {
            id: 'companyId',
            checks: ['required']
        },
        {
            id: 'primaryUserId',
            checks: ['required']
        },
        {
            id: 'primaryContact-name',
            checks: ['required']
        },
        {
            id: 'primaryContact-phoneNumber',
            checks: ['phone']
        },
        {
            id: 'primaryContact-email',
            checks: ['email']
        },
        {
            id: 'secondaryContact-phoneNumber',
            checks: ['phone']
        },
        {
            id: 'secondaryContact-email',
            checks: ['email']
        },
        {
            id: 'address-streetName1',
            checks: ['required'],
        },
        {
            id: 'address-city',
            checks: ['required'],
        },
        {
            id: 'address-zip',
            checks: ['zip'],
        }
    ]
}
