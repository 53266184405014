import { text as t } from 'shared/text'

export const gameroomPerformanceReportsLayout = {
    tableHeaders: {
        locationId: t.locationId,
        locationName: t.locationName,
        companyId: t.companyId,
        companyName: t.companyName,
        totalPlays: t.plays,
        totalCashIn: t.cashIn,
        totalVTP: t.vtp,
        totalWins: t.totalWins,
        profit: t.profit,
        rtp: t.rtp,
        averageBet: t.averageBet,
        averageWin: t.averageWin,
        highestWin: t.highestWin,
        hitFrequencyPercentage: t.hitFrequencyPercentage,
    },

    sizes: {
        locationName: 'large',
        companyName: 'large',
        totalPlays: 'mid',
        totalCashIn: 'large',
        totalVTP: 'large',
        totalWins: 'mid',
        profit: 'large',
        rtp: 'mid',
        averageBet: 'mid',
        averageWin: 'mid',
        highestWin: 'mid',
        hitFrequencyPercentage: 'mid',
    },

    layouts: {
        locationId: 'hidden',
        locationName: 'text-left',
        companyId: 'hidden',
        companyName: 'text-left left-pad',
        totalPlays: 'text-right',
        totalCashIn: 'text-right',
        totalVTP: 'text-right',
        totalWins: 'text-right',
        profit: 'text-right',
        rtp: 'text-right',
        averageBet: 'text-right',
        averageWin: 'text-right',
        highestWin: 'text-right',
        hitFrequencyPercentage: 'text-right right-pad',
    },

    formats: {
        locationId: 'hidden-reference',
        companyId: 'hidden-reference',
        totalPlays: 'number',
        totalCashIn: 'currency-whole',
        totalVTP: 'currency-whole',
        totalWins: 'currency-whole',
        profit: 'currency-whole',
        rtp: 'percentage-whole',
        averageBet: 'currency',
        averageWin: 'currency',
        highestWin: 'currency-whole',
        hitFrequencyPercentage: 'percentage-whole'
    },

    navigation: {
        locationName: {
            root: 'location',
            label: 'locationName',
            value: 'locationId',
            ending: 'reports/gameroom',
        },
        companyName: {
            root: 'company',
            label: 'companyName',
            value: 'companyId'
        },
    },

    subtotals: {
        groupBy: 'all',
        fields: {
            locationName: 'header',
            totalPlays: 'total',
            totalCashIn: 'total',
            totalVTP: 'total',
            totalWins: 'total',
            profit: 'total',
            rtp: {
               first: {column:'totalWins', type: 'total'},
                calc: 'divide',
                second: {column:'totalVTP', type: 'total'}
            },
            averageBet: {
                first: {column:'totalVTP', type: 'total'},
                calc: 'divide',
                second: {column:'totalPlays', type: 'total'},
            },
            averageWin: {
                first: {column:'totalWins', type: 'total'},
                calc: 'divide',
                second: {column:'numberOfWins', type: 'total'}
            },
            highestWin:'max',
            hitFrequencyPercentage: {
                first: {column:'numberOfWins', type: 'total'},
                calc: 'divide',
                second: {column:'totalPlays', type: 'total'}
            }
        },
        formats: {
            totalPlays: 'number',
            totalCashIn: 'currency-whole',
            totalVTP: 'currency-whole',
            profit: 'currency-whole',
            rtp: 'percentage-whole',
            averageBet: 'currency',
            averageWin: 'currency',
            highestWin: 'currency-whole',
            totalWins: 'currency-whole',
            hitFrequencyPercentage: 'percentage-decimal-whole',
        }
    }
}
