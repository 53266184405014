import { createContext, useState } from 'react';
import ModalPortal from './ModalPortal';

const ModalContext = createContext({});

export const ModalProvider  = ({ children }) => {
  const [ modal, setModal ] = useState(false);
  const [ modalTrigger, setModalTrigger ] = useState('');

  return (
    <ModalContext.Provider value={{ 
        modal, setModal, 
        modalTrigger, setModalTrigger
    }}>
      <ModalPortal/>
      {children}
    </ModalContext.Provider>
  );
};

export default ModalContext