import { text as t } from 'shared/text'

export const locationPerformanceReportsLayout = {
    defaultSort: {
        orderBy: 'locationName',
        desc: false,
    },

    tableHeaders: {
        locationName: t.locationName,
        companyName: t.companyName,
        kioskId: t.device,
        deviceType: t.deviceType,
        stateCode: t.state,
        cashIn: t.cashIn,
        cashOut: t.cashOut,
        promo: t.promo,
        profit: t.profit
    },

    sizes: {
        locationName: 'large',
        companyName: 'large',
        kioskId: 'mid',
        deviceType: 'large',
        stateCode: 'small',
        cashIn: 'mid',
        cashOut: 'mid',
        promo: 'mid',
        profit: 'mid',
    },

    layouts: {
        locationName: 'text-left',
        companyName: 'text-left',
        kioskId: 'text-left',
        deviceType: 'text-left',
        stateCode: 'text-left',
        cashIn: 'text-right',
        cashOut: 'text-right',
        promo: 'text-right',
        profit: 'text-right',
    },

    formats: {
        cashIn: 'currency',
        cashOut: 'currency',
        promo: 'currency',
        profit: 'currency',
    },

    navigation: {
        locationName: {
            root: 'location',
            label: 'locationName',
            value: 'locationId',
        },
        companyName: {
            root: 'company',
            label: 'companyName',
            value: 'companyId',
        },
        kioskId: {
            root: 'device',
            label: 'kioskId',
            value: 'kioskId'
        }
    },

    subtotals: {
        groupBy: 'locationName',
        fields: {
            locationName: 'header',
            cashIn: 'total',
            cashOut: 'total',
            promo:'total',
            profit:'total',
        },
        formats: {
            cashIn: 'currency',
            cashOut: 'currency',
            promo:'currency',
            profit:'currency',
        }
    }
}
