import { useContext } from 'react';
import ModalContext from './ModalContext';

const useModal = () => {
  const { modal, setModal, 
          modalTrigger, setModalTrigger } = useContext(ModalContext);

    const addModal = (content, trigger) => {
      setModal(content);
      trigger && setModalTrigger(trigger);
    }
    const removeModal = (trigger) => {
      setModal(false);
      trigger && setModalTrigger(trigger);
    }

  return { modal, setModal,
           modalTrigger, setModalTrigger,
           addModal, removeModal };
};

export default useModal;