import {text as t} from 'shared/text';

export const companyLocationsLayout = {
    tableHeaders: {
        name: t.name,
        id: t.id,
        city: t.city,
        stateCode: t.state,
        collector: t.assignedUser,
    },

    sizes: {
        name: 'large',
        id: 'small',
        city: 'small',
        stateCode: 'mid',
        collector: 'mid',
    },

    layouts: {
        name: 'text-left',
        id: 'text-left',
        city: 'text-left',
        stateCode: 'text-left',
        collector: 'text-left',
    },

    navigation: {
        name: {
            root: 'location',
            label: 'name',
            value: 'id',
        },
        collector: {
            root: 'user',
            label: 'collector.label',
            value: 'collector.id',
        },
    },

    rowCount: 20,
}
