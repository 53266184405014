import './tooltip.scss';
import { useEffect, useState, Fragment } from 'react';
import useComponents from 'components/hooks/Components/useComponents';

const Tooltip = () => {
    const { tooltipData, changeTooltipData } = useComponents();
    const [ showTooltip, setShowTooltip ] = useState();
    const [ showEllipses, setShowEllipses ] = useState(false);

    useEffect(()=>{
        const tooltip = document.getElementById('tooltip');
        if (!tooltipData) {
            if (tooltip.style) {tooltip.style=null};
            setShowTooltip(false);
        } else {
            const id = tooltipData.id;
            const type = tooltipData.type;
            if (id) {
                const width = window.innerWidth;
                const height = window.innerHeight;
                const cell = document.getElementById(id);                
                const cellBounds = cell.getBoundingClientRect();
                const tooltipWidth = tooltip.clientWidth;
                const tooltipHeight = tooltip.clientHeight;
                const right = width - cellBounds.right;
                const left = cellBounds.left;
                const top = cellBounds.top + document.getElementById('App').scrollTop;
                const bottom = height - cellBounds.bottom;
                if (type=== 'cell') {
                    if (tooltipWidth + 5 < right) {
                        tooltip.style.left = Math.floor(cellBounds.x + cellBounds.width/2) + 'px';
                    }  else if (tooltipWidth + 5 < left) {
                        tooltip.style.right = Math.floor(cellBounds.right - cellBounds.right + cellBounds.width/2) + 'px';
                    } else {
                        tooltip.style.right='0px';
                    }
                    if (tooltipHeight + 5 < top) {
                        tooltip.style.top = Math.floor(top - tooltipHeight - cellBounds.height/2) + 'px';
                    } else if (tooltipHeight + 5 < bottom ) {
                        tooltip.style.top = Math.floor(top - cellBounds.height/2) + 'px';
                    } else {
                        tooltip.style.top = '0px';
                    }
                } else {
                    if (tooltipWidth + 5 < right) {
                        tooltip.style.left = Math.floor(cellBounds.x) + 'px';
                    } else {
                        tooltip.style.right = Math.floor(cellBounds.x) + 'px';
                    }
                    tooltip.style.top = Math.floor(top - tooltipHeight - 10) + 'px';
                }
            }
            setShowTooltip(true)
        }
    }, [tooltipData]);

    const handleMouseLeave = (event) => {
        const targetId = event.relatedTarget.id;
        if (tooltipData?.id !== targetId) {
            changeTooltipData(null);
            window.getSelection().removeAllRanges();
        }
    }

    const renderTextWithLineBreaks = (text) => {
        return text.split('\n').map((line, index) => (
          <Fragment key={index}>
            {line}{index < text.split('\n').length - 1 && <br />}
          </Fragment>
        ));
    }

    return (
        <div className='tooltip-anchor' onMouseLeave={(event) => handleMouseLeave(event)}>
            <div id='tooltip' className={`tooltip ${showTooltip ? 'show' : ''}`}>
                <div id='tooltip-content' className='tooltip-content'>
                    {tooltipData?.content && renderTextWithLineBreaks(tooltipData.content)}
                </div>
                {/* {showEllipses && <div className='tooltip-ellipses'>...</div>} */}
            </div>
        </div>
    )
}

export default Tooltip;