// styling comes from the Checkbox component css for conformity.
import { useEffect, useState } from 'react';
import { removeFromArray } from 'components/shared/componentUtils';
import useTables from '../TableHooks/useTables';
import Icon from 'components/Icon/Icon';

const TableCheckbox = ({ id, tableId,
                         tableState, prop, 
                         classes, label, 
                         reversed, disabled, 
                         masked, displayOnly }) => {
    const { updateTable, getState } = useTables();
    const [ maskState, setMaskState ] = useState();
    const state = getState(tableState)?.[tableId]?.includes(prop) ? true: false;
    const checked = reversed ? !state : state;

    useEffect(() => {
        if (!masked) {
            setMaskState('fading-pseudo');
            setTimeout(() => {
                setMaskState('fading-mask');
                setTimeout(() => {
                    setMaskState('faded');
                }, 0);
            }, 0); 
        } else {
            setMaskState(false);
        }
    }, [masked]);

    const onCheck = () =>{
        if (disabled || masked) {return}
        const newStates = getState(tableState)?.[tableId] ? getState(tableState)[tableId] : [];
        if (newStates.includes(prop)) {
            removeFromArray(prop, newStates);
        } else {
            newStates.push(prop);
        }
        updateTable(tableState, tableId, newStates.length > 0 ? newStates : null);
    }

    return (
        <div id={id + '-checkbox'} className= {`checkbox-container ${classes ? classes : ''} ${!label ? 'hide-label' : ''} ${displayOnly ? 'display-only' : ''}`}>
            {label ? <div className='checkbox-label'>{label}</div> : null}
            <div className='checkmark-container'>
                <span
                    className={`checkmark ${disabled ? 'disabled': ''} ${checked && maskState ==='faded' ? 'checked' : ''}`}
                    tabIndex='-1'
                    onClick={()=>{onCheck()}}
                >
                    <div className={`checkbox-mask ${maskState}`}/> 
                    <Icon icon='check' classes='checkmark-check'/>
                </span>
            </div>
        </div>
    )
}

export default TableCheckbox;
