import './apiLogs.scss';
import { useEffect, useRef, useState } from 'react';
import { apiLogsLayout as layout } from 'layouts/APILogs/APILogsLayout';
import { apiLogDetailsLayout as detailsLayout } from 'layouts/APILogs/APILogDetailsLayout';
import { getDefaultDateRange } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import useUtils from 'hooks/useUtils';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Page from 'app-components/Page/Page';
import TableListed from 'components/Tables/TableListed';
import TablePaged from 'components/Tables/TablePaged';
const logsId = 'logs-table';
const detailsId = 'logs-details-table';

const APILogs = () => {
    const { callAPI, callAPIGet } = useUtils();
    const { tableData, tableSelectedRows, updateTable } = useTables();
    const [ logDetails, setLogDetails ] = useState();
    const [ selected, setSelected ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ detailsMasked, setDetailsMasked ] = useState([true]);
    const runOnce = useRef(false);

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true; 
        const range = getDefaultDateRange(logsId);
        setDefaultDates(range);
    }, []);

    useEffect(()=> {
        const selected = tableSelectedRows?.[logsId] ? tableSelectedRows?.[logsId] : false;
        setSelected(selected);
        if (selected) {
            const prefix = logsId + '-row-';
            const index = selected.substring(selected.indexOf(prefix) + prefix.length);
            const rowId = tableData[logsId][index].id;
            setDetailsMasked([true]);
            callAPI('loggerDetails', loggerDetailsCallback, {gatewayLoggerId: parseInt(rowId)});
        }
    }, [tableSelectedRows?.[logsId]])

    const getAPILogs = (data, callback) => {
        const params = {
            Search: data.searchTerm,
            PageIndex: data.currentPage,
            PageSize: data.rowCount,
            OrderBy: data.orderBy,
            Desc: data?.desc,
        }
        if (data.periodStart) {
            params.PeriodStart = data.periodStart;
            params.PeriodEnd = data.periodEnd;
        }
        data.callback = callback;
        callAPIGet('apiLogs', apiLogsCallback, params, data)
    }

    const apiLogsCallback = (data, props) => {
        if (data.tableData && props.callback) {
            props.records = data.tableData;
            props.pageCount = data.pageCount ? data.pageCount : '';
            props.callback(props);
        }
    }

    const loggerDetailsCallback = (data) => {
        setLogDetails(data?.tableData ? data.tableData : []);
        setDetailsMasked([false]);
    }

    const onDateRangePick = (range) => {
        updateTable('selectedRows', logsId, null);
        setTimeout(() => {
            updateTable('dates', logsId, range);
        }, 0)
    }

    return (
        <Page page={t.apiLogs}>
            <TablePaged
                id={logsId}
                classes='api-logs-table'
                layout={layout}
                defaultDates={defaultDates}
                apiId='apiLogs'
                apiCall={getAPILogs}
                searchBarContent={<>
                <DateRangePicker
                    classes='quarter'
                    defaultVal={defaultDates}
                    label={t.dateRange}
                    callback={onDateRangePick}/>
                </>}
            />
            {selected && <TableListed 
                id={detailsId}
                classes='api-logs-table'
                data={logDetails}
                layout={detailsLayout} 
                masked={detailsMasked[0]}
                noURL={true}
            />}
        </Page>
    )
}

export default APILogs;