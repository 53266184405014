export const tablesData = {
    allNoCap: 'all',
    first: 'First',
    last: 'Last',
    na: 'N/A',
    next: 'Next',
    noResults: '- no results -',
    nullVal: '-',
    previous: 'Previous',
    recordsNoCap: 'records',
    recordsShownNoCap: 'records shown',
    results: 'Results',
    search: 'Search',
    selectTableColumns: 'Selected Table Columns',
    showNoCap: 'show',
    subtotals: 'Subtotals',
    total: 'Total',
    unknown: 'unknown',
    updateColumns: 'Update Columns',
    defaultRowCount: 10,
    rowCounts: [10, 20, 50, 100],
}