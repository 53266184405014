import './dropdownOption.scss';
import { getObjectLabel } from 'components/shared/componentUtils';

const DropdownOption = ({i, item, valueProp, labelProp, appendProp, callback}) => {
        const value = item?.[valueProp] ? item[valueProp] : item.value;
        const label = getObjectLabel(item, labelProp, appendProp, valueProp);
        const type = item.type;
        const onSelect = ( target ) => {
            callback(target)
        }
        return (
            <> {type === 'placeholder'
            ? <div 
                className='dropdown-option dropdown placeholder' 
                disabled 
                data-dropdown={true} 
            >{label}</div>
            : <div 
                tabIndex='-1'
                className='dropdown-option dropdown' 
                data-index={i}
                data-value={value} 
                data-label={label}
                data-dropdown={true}
                data-type={item.type} 
                onClick={( event ) => {onSelect(event.currentTarget)
            }}>{label}</div>
            }</>
        )
}

export default DropdownOption;
