import { text as t } from 'shared/text';

export const devicesGamesLayout = {
    id: 'devices-games',
    page: t.devices,
    subpage: t.games,

    defaultSort:  {
        prop: 'id',
        desc: false,
    },

    tableHeaders: {
        id: t.deviceId,
        machineSerialNumber: t.serialNumber,
        locationName: t.locationName,
        cassettes: [t.cassette],
        stacker: t.stacker,
    },

    sizes: {
        id: 'mid',
        machineSerialNumber: 'mid',
        locationName: 'mid',
        cassettes: 'mid',
        stacker: 'mid',
    },

    layouts: {
        id: 'text-left',
        machineSerialNumber: 'text-left',
        locationName: 'text-left',
        cassettes: 'text-left',
        stacker: 'text-left',
    },

    navigation: {
        id: {
            root: 'device',
            label: 'id',
            value: 'id',
        },
        locationName: {
            root: 'location',
            label: 'locationName',
            value: 'locationId'
        }
    },

    hideSortArrows: {
        stacker: true,
    }
}
