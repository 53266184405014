import './gamePerformanceReports.scss';
import { useEffect, useRef, useState } from 'react';
import { gamePerformanceReportsLayout as layout } from 'layouts/Reports/GamePerformanceReportsLayout';
import { getDefaultDateRange } from 'components/shared/componentUtils';
import { reportsData, deviceTypes } from 'shared/data';
import { text as t } from 'shared/text';
import * as e from 'shared/excel';
import useInputs from 'components/hooks/Inputs/useInputs';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Dropdown from 'components/Dropdown/Dropdown';
import TablePaged from 'components/Tables/TablePaged';
const tableId = 'device-game-performance';
const typeId = 'TerminalTypeId';

const GamePerformanceReports = () => {
    const { inputValues, updateDropdown } = useInputs();
    const { tableDropdowns,  updateDropdowns, 
            updateTable, getURLParams } = useTables();
    const { callAPIGet } = useUtils();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ masked, setMasked ] = useState([true]);

    const runOnce = useRef(false);

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true; 
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
    }, []);

    useEffect(() => {
        if (inputValues?.[reportsData.dropdownId]){
            const urlParams = getURLParams(tableId);
            if (urlParams.dropdowns) {
                const sorts = urlParams.dropdowns?.[typeId];
                sorts && updateDropdown(typeId, sorts.value, sorts.label);
            }
        }
    }, [inputValues?.[reportsData.dropdownId]])

    const getReportsData = (data, callback) => {
        setMasked([true]);
        const params = {
            Search: data.searchTerm,
            PageIndex: data.currentPage,
            PageSize: data.rowCount,
            OrderBy: data.orderBy,
            Desc: data?.desc,
        }
        const dropdowns = tableDropdowns?.[tableId];
        if (dropdowns) {
            Object.keys(dropdowns).forEach((key) => {
                params[key] = dropdowns[key].value;
            });
        }
        if (data.periodStart) {
            params.PeriodStart = data.periodStart;
            params.PeriodEnd = data.periodEnd;
        }
        data.callback = callback;
        callAPIGet('report-GamePerformance', gamePerformanceCallback, params, data);
    }
    const gamePerformanceCallback = (data, props) => {
        setMasked([false]);
        if (data.data && props.callback) {
            props.records = data.data;
            props.pageCount = data.pageCount ? data.pageCount : '';
            props.callback(props);
        }
    }

    const onDeviceTypeSelect = (data) => {
        const newData = {
            value: data.value,
            label: data.label
        }
        updateDropdowns(tableId, typeId, newData);
    }

    const onDateRangePick = (range) => {
        updateTable('selectedRows', tableId, null);
        setTimeout(() => {
            updateTable('dates', tableId, range);
        }, 0)
    }

        // const onExportToExcel = () => { 
    //     const dateInfo = {
    //         type: 'table',
    //         columns: ['startDate', 'endDate'],
    //         headers: {
    //             selector: t.deviceType,
    //             startDate: t.startDate,
    //             endDate: t.endDate,
    //         },
    //         data:[{
    //             startDate: e.getExcelDate(currentRange[0]),
    //             endDate: e.getExcelDate(currentRange[1]),
    //         }],
    //         configs: {
    //             formats: {selector: 'text-wrap'}
    //         }
    //     };
    //     if (tableDropdowns?.[tableId]?.[typeId]) {
    //         dateInfo.data[0].selector = tableDropdowns[tableId][typeId].label,
    //         dateInfo.columns.splice(0,0, 'selector');
    //     }

    //     const tableRowData = deepCopy(tableData[tableId]);
    //     if (layout.subtotals){
    //         tableRowData.push({totals: tableTotals[tableId]});
    //     }
    //     const columns = getTableColumns(tableId, layout);
    //     const tableInfo = e.getExcelTable(t.gamePerformance, columns, tableRowData, layout);
    //     const sizes = columns.map(key => layout.sizes[key]);
    //     e.exportToFile('Gameroom Performance Report', [dateInfo, tableInfo], sizes);
    // }

    return (<>
         <TablePaged
                id={tableId}
                layout={layout}
                defaultDates={defaultDates}
                apiId='report-GamePerformance'
                apiCall={getReportsData}
                searchBarContent={<>
                <Dropdown
                    id={typeId}
                    classes='quarter'
                    label={t.terminalType}
                    data={deviceTypes}
                    masked={masked?.[0]}
                    nullable={t.all}
                    hideErrors={true}
                    callback={onDeviceTypeSelect}
                />
                <DateRangePicker
                    classes='quarter'
                    defaultVal={defaultDates}
                    label={t.dateRange}
                    stateId={tableId}
                    callback={onDateRangePick}/>
                </>}
            />
    </>)
}

export default GamePerformanceReports;