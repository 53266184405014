export const text = {
    404: 'Request endpoint not found',
    abbr: 'Abbr',
    accountLevel: 'Account Level',
    actions: 'Actions',
    activations: 'Activations',
    active: 'Active',
    activeLast: 'Last Made Active',
    activePlayers: 'Active Players',
    activity: 'Activity',
    activityLog: 'Activity Log',
    addCompany: 'Add Company',
    addIP: 'Add IP',
    addLocation: 'Add Location',
    addManifest: 'Add Manifest',
    addNewRole: 'Add New Role',
    addRole: 'Add Role',
    addSource: 'Add Source',
    addUser: 'Add User',
    addUserToCompany: 'Add User to Company',
    addNewCompany: 'Add New Company',
    addNewLocation: 'Add New Location',
    addNewManifest: 'Add New Manifest',
    addNewSource: 'Add New Source',
    addNewUser: 'Add New User',
    address: 'Address',
    addressOne: 'Address 1',
    addressTwo: 'Apt, Suite Number (Optional)',
    admin: 'Admin',
    adminOnly: 'Admin Only',
    adminPinChange: 'Change Admin PIN',
    adminPinCreate: 'Create Admin PIN',
    adminPinManage: 'Manage Admin PIN',
    administrator: 'Administrator',
    alertEmail: 'Email Alert',
    alertForTen: '$10.00 count',
    alertForTenUGD: '10.00 count',
    alertForTwenty: '$20.00 count',
    alertForTwentyUGD: '20.00 count',
    alertPrimary: 'Alert Primary',
    alertSecondary: 'Alert Secondary',
    alertText: 'Text Alert',
    all: 'All',
    apiLogs: 'API Logs',
    assign: 'Assign',
    piggyBank: 'Piggy Bank Deluxe',
    piggyBankUG: 'Piggy Bank UG',
    amount: 'Amount',
    apilogs: 'API Logs',
    apiBaseUrl: 'API Base URL',
    applyRole: 'Apply a Role',
    applyToRole: 'Apply Permissions to the Role',
    ar: 'A/R',
    arReports: 'A/R Reports',
    assignedCollectors: 'Assigned Collectors',
    assignedUser: 'Assigned User',
    assignment: 'Assignment',
    assignmentNotice: 'It may take a minute for the system to fully reassign this device. You can close this modal, but please remain on the page until the Assign button returns and you see notice "Update Complete".',
    assignmentInProgress: 'Assignment In Progress',
    audit: 'Audit',
    authenticated: 'Authenticated',
    averageBet: 'Avg Bet',
    averageWin: 'Avg Win',
    back: 'Back',
    batchAssignment: 'Batch Assignment',
    betLevel: 'Bet Level',
    billAcceptorInitialized: 'Bill Acceptor Initialized',
    billAcceptorOperational: 'Bill Acceptor Operational',
    billingContact: 'Billing Contact',
    billingInfo: 'Billing Information',
    bingo: 'Bingo',
    bingoKiosk: 'Bingo Kiosk',
    boardSerial: 'Board #',
    bonusFreespins: 'Free',
    branding: 'Branding',
    brandingInstructions: 'Click here to upload a logo. This image will be resized to 185px wide by 42px tall. Ex:',
    uploadCSV: 'Upload CSV',
    uploadedBrandingInstructions: 'Click here to change a logo. This image will be resized to 185px wide by 42px tall.',
    cabinet: 'Cabinet',
    cabinetSerial: 'Cabinet #',
    callerId: 'Caller ID',
    cancel: 'Cancel',
    cashBreakdown: 'Cash Breakdown',
    cashIn: 'Cash In',
    cashInKiosk: 'Cash In Kiosk',
    cashOut: 'Cash Out',
    cassette: 'Cassette',
    cassettes: 'Cassettes',
    cassetteStatus: 'Cassette Status',
    changeAdminPassword: 'Change Admin Password',
    changeAdminPinWarning: 'This will change the admin PIN for ALL GRC Kiosks under this user account. Are you sure you want to continue?',
    changeColumns: 'Change Columns',
    changePassword: 'Change Password',
    chooseCSV: 'Choose CSV',
    city: 'City',
    clear: 'Clear',
    clearAll: 'Clear All',
    close: 'Close',
    collectFirst: 'First Collected',
    collection: 'Collection',
    collections: 'Collections',
    collectionDetails: 'Collection Details',
    collectionName: 'Collection Name',
    collectionType: 'Collection Type',
    collector: 'Collector',
    collectorId: 'Collector ID',
    collectors: 'Collectors',
    collectorsList: 'Collectors List',
    commission: 'Commission',
    commissioned: 'Commissioned',
    companies: 'Companies',
    company: 'Company',
    companyDetails: 'Company Details',
    companyId: 'Company ID',
    companyInfo: 'Company Information',
    companyName: 'Company Name',
    companyPerformance: 'Company Performance',
    companyPerformanceReports: 'Company Performance Reports',
    companyPermissions: 'Company Permissions',
    configuration: 'Configuration',
    configureSettingsOptional: 'Congigure Settings (optional)',
    confirm: 'Confirm',
    confirmLogout: 'Are you sure you want to log out?',
    confirmNewPassword: 'Confirm New Password',
    confirmPassword: 'Confirm Password',
    connectedGames: 'Connected Games',
    connectedToGateway: 'Connected To Gateway',
    contactName: 'Contact Name',
    contacts: 'Contacts',
    contractScope: 'Contract Jurisdiction',
    controls: 'Controls',
    copied: 'Copied',
    country: 'Country',
    county: 'County',
    create: 'Create',
    createAndApplyNewRole: 'Create and Apply New Role',
    createdAt: 'Created At',
    createNewRole: 'Create New Role',
    createNewUser: 'Create New User',
    createdOn: 'Created On',
    creatorID: 'Creator ID',
    credentialsExpired: 'Credentials Expired. Please Log Back In.',
    currentLocation: 'Current Location',
    currentLocations: 'Current Locations',
    currency: 'Currency',
    currentAccount: 'Current Account',
    currentCompany: 'Current Company',
    currentJackpots: 'Current Jackpots',
    currentRole: 'Current Role',
    currentWeek: 'Current Week',
    customer: 'Customer',
    customerName: 'Customer Name',
    dashboards: 'Dashboards',
    dataNotFound: 'No data found for ',
    dataMismatch: 'Value not found in options list',
    dateCreated: 'Date Created',
    dateInstalled: 'Date Installed',
    dateRange: 'Date Range',
    dateTime: 'Date Time',
    day: 'Day',
    decommission: 'Decommission',
    delete: 'Delete',
    deleted: 'Deleted',
    deleteAccount: 'Delete Account',
    deleteAccounts: 'Delete Accounts',
    deleteAccountsWarning: "You are about to delete a player's account(s). This cannot be reversed. Are you sure you want to proceed?",
    deleteConclude: 'Deus Vult',
    deleteConfirm: 'PURGE',
    deleteRole: 'Delete Role',
    deposits: 'Deposits',
    deselect: 'Deselect',
    details: 'Details',
    detailType: 'Detail Type',
    device: 'Device',
    deviceId: 'Device ID',
    deviceType: 'Device Type',
    deviceSubtype: 'Device Subtype',
    deviceCommands: 'Device Commands',
    deviceInfo: 'Device Info',
    deviceLogs: 'Device Logs',
    devices: 'Devices',
    deviceTransactions: 'Device Transactions',
    destination: 'Destination',
    download: 'Download',
    downloadLog: 'Download Log',
    dropdownDivider: '-------------',
    due: 'Due',
    duplicateCheckbox: 'Same as Company Info',
    edit: 'Edit',
    editPermissions: 'Edit Permissions',
    email: 'Email',
    emailNotExist: 'Email does not exist in the system',
    emailInvalid: 'Email invalid. Please try again.',
    emailIsAlreadySent: 'Email has been sent.',
    emailTaken: 'Email already in use',
    emailUnchecked: "Email couldn't be checked",
    enabled: 'Enabled',
    encryptAndDownload: 'Encrypt & Download',
    enter: 'Enter',
    enterIPAddress: 'Enter 3rd party IP address',
    enterListItem: 'Enter List Item',
    enterLocationType: 'Enter Location Type',
    enterUsername: 'Enter Username',
    enterPassword: 'Enter Password',
    enterPhoneNumber: 'Enter Phone Number',
    environment: 'Environment',
    endDate: 'End Date',
    entriesNoCap: 'entries',
    error: 'Error',
    errorEmail: 'Please enter a valid email',
    errorEmailInvalid: 'No email found',
    errorEqual: 'Please enter a value equal to',
    errorPhone: 'Please enter a valid phone number',
    errorPhoneInvalid: 'No phone number found',
    errorRequired: 'Required',
    errorSelectOne: 'Must select one',
    errorShareRates: 'Operator and Location Shares must add up to 100',
    errorZip: 'Please put in a valid zip code',
    errGeneral: 'Whoops! Looks like something went wrong.',
    escrow: 'Escrow',
    executeScript: 'Execute Script',
    executeScriptConfirmMessage: 'Enter command. Do not enter ‘sudo’ as this will be done by the device',
    existingUser: 'Existing User',
    existingUsers: 'Existing Users',
    exitHere: 'Exit Here',
    exportToExcel: 'Export to Excel',
    exterminatus: 'Exterminatus',
    fail: 'Fail',
    false: 'False',
    fileName: 'File Name',
    financials: 'Financials',
    firmwareVersion: 'Firmware Version',
    first: 'First',
    firstContact: 'First Contact',
    firstName: 'First Name',
    fullName: 'Full Name',
    forgotPassword: 'Forgot Password',
    forgotPasswordInstructions: 'Enter your email below to reset your password.',
    formErrors: 'Form Errors',
    game: 'Game',
    gameBaseUrl: 'Game Base URL',
    gameDetails: 'Game Details',
    gameroom: 'Gameroom',
    gameroomPerformance: 'Gameroom Performance',
    gameroomPerformanceReport: 'Gameroom Performance Report',
    gameroomPOS: 'Gameroom POS',
    games: 'Games',
    gamePerformance: 'Game Performance',
    gamePerformanceReport: 'Game Performance Report',
    gameType: 'Game Type',
    general: 'General',
    generateLicenseAndDownload: 'Generate License & Download',
    generateLicensesAndDownload: 'Generate License(s) & Download',
    goldRushCityKiosk: 'Gold Rush City Kiosk',
    grand: 'Grand',
    grc: 'GRC',
    grcAlerts: 'GRC Kiosk Low Bill Alerts',
    grcKiosks: 'GRC Kiosks',
    grcPOS: 'GRC POS',
    grcPointofSale: 'GRC Point of Sale',
    handPay: 'Hand Pay',
    headers: 'Headers',
    hidePassword: 'Hide Password',
    hidePermissions: 'Hide Permissions',
    highestWin: 'Top Win',
    hitFrequency: 'Hit Freq (1 in X)',
    hitFrequencyPercentage: 'Hit Freq %',
    hold: 'Hold',
    holdPercentage: 'Hold %',
    home: 'Home',
    id: 'ID',
    idleMessage: 'Are you still there?',
    includedLocations: 'Included Locations',
    inheritance: 'Inheritance',
    inheritedRole: 'Inherited Role',
    inputSearch: 'Search',
    installDate: 'Install Date',
    inService: 'In Service',
    inventory: 'Inventory',
    inviteUser: 'Invite User',
    ipAddress: 'IP Address',
    isActive: 'Is Active',
    isNewInstall: 'For New Installs',
    isPrimary: 'Is Primary',
    jackpotType: 'Jackpot Type',
    jackpots: 'Jackpots',
    jurisdiction: 'Jurisdiction',
    jurisdictionCity: 'Enter a [city/county] to be referenced:',
    jurisdictionState: 'Enter state or Commonwealth Name:',
    kioskPerformance: 'Kiosk Performance',
    kioskPerformanceReport: 'Kiosk Performance Report',
    kiosk: 'Kiosk',
    kiosks: 'Kiosks',
    kiosksReport: 'Kiosks Report',
    last: 'Last',
    lastModified: 'Last Modified',
    lastMonth: 'Last Month',
    lastName: 'Last Name',
    lastOnline: 'Last seen online',
    lastQuarter: 'Last Quarter',
    lastWeek: 'Last Week',
    licenseRate: 'License Rate',
    licenseRatePercentage: 'License Rate %',
    livePerformance: 'Live Performance',
    liveReport: 'Live Report',
    list: 'List',
    lists: 'Lists',
    lobbyBaseUrl: 'Lobby Base URL',
    locationContacts: 'Location Contacts',
    locationManager: 'Location Manager',
    localServiceRunning: 'Local Service Running',
    location: 'Location',
    locationPerformance: 'Location Performance',
    locationPerformanceReports: 'Location Performance Report',
    locationRate: 'Location Share',
    locations: 'Locations',
    locationDetails: 'Location Details',
    locationId: 'Location ID',
    locationName: 'Location Name',
    locationPercentage: 'Location %',
    locationRole: 'Location Roles',
    locationRoles: 'Location Roles',
    locationStartDate: 'Location Start Date',
    locationType: 'Location Type',
    locationTypes: 'Location Types',
    loggingOut: 'Logging Out',
    login: 'Login',
    loginFailed: 'Login invalid. Please try again.',
    logout: 'Logout',
    logs: 'Logs',
    logClass: 'Log Class',
    major: 'Major',
    manage: 'Manage',
    manager: 'Manager',
    manifest: 'Manifest',
    manifests: 'Manifests',
    manualCollect: 'Manually Collected',
    mac: 'MAC address',
    message: 'Message',
    messages: 'Messages',
    mini: 'Mini',
    minor: 'Minor',
    missingPassword: 'Please enter a password.',
    missingUsername: 'Please enter a username.',
    monitor: 'Monitor',
    monitors: 'Monitors',
    monthToDate: 'Month to Date',
    na: 'N/A',
    name: 'Name',
    nameAlreadyTaken: 'Name already taken',
    needGRCKiosk: "Available once the user's company has a GRC Kiosk",
    net: 'Net',
    netCash: 'Net Cash',
    netRevenue: 'Net Revenue',
    newLocationType: 'Enter new Location Type',
    newLocations: 'New Locations',
    newPassword: 'New Password',
    newPlayers: 'New Players',
    newRoleName: 'New Role Name',
    newUser: 'New User',
    newUserEmail: 'New User Email',
    next: 'Next',
    none: 'None',
    noCompany: 'No Company',
    noData: 'No Data Found',
    noDevice: 'No Device',
    noDeviceSubtype: 'No Device Subtype',
    noDeviceType: 'No Device Type',
    noFeaturesAvailable: 'No Features Available',
    noFileSelected: 'No File Selected',
    noLocation: 'No Location',
    noPurchaseNecessary: 'No Purchase Necessary',
    noResults: '- no results -',
    noRoleAssigned: '- no role assigned -',
    noRoles: 'No Roles',
    noUser: 'No User',
    noUserData: 'No data available for this user',
    notSet: 'Not Set',
    nullOption: '(null)',
    nullVal: '-',
    numberOfWins: '# of Wins',
    oldPassword: 'Old Password',
    online: 'Online',
    onlineNow: 'Online as of',
    operatorPercentage: 'Operator %',
    operatorRate: 'Operator Share',
    openSupportTunnel: 'Open Support Tunnel',
    openSupportTunnelConfirmMessage: 'This will open a reverse SSH tunnel to the device.  Use the following command: ‘ssh -i "devmag2vrd.pem" ubuntu@tunnel.trueroute.com’',
    owner: 'Owner',
    pageNotFound: '404: Page Not Found',
    parent: 'Parent',
    password: 'Password',
    passwordConfirmationError: 'Password not equal',
    passwordResetInstructions: 'Enter your new password below.',
    pauseService: 'Pause Service',
    pausedService: 'Service Paused',
    pausedManually: 'Manually Paused',
    paybackPuzzleRounds: 'Payback Puzzle Rounds',
    payRate: 'Pay Rate',
    percentage: 'Percentage',
    pass: 'Pass',
    permissions: 'Permissions',
    permissionsOverride: 'You are overriding permissions for:',
    phoneNumber: 'Phone Number',
    playType: 'Play Type',
    playerCredits: 'Player Credits',
    playsAll: 'All Plays',
    playsGameroom: 'Only Gameroom Plays',
    playsMobile: 'Only Mobile Plays',
    pointOfSales: 'Point of Sales',
    pos: 'POS',
    previous: 'Previous',
    pricingGlobal: 'Global Pricing',
    primePayLabel: 'Prime Pay',
    primePay: 'PrimePay',
    primePay2: 'PrimePay V2',
    primePays: 'PrimePays',
    primePayKiosk: 'PrimePay Kiosk',
    principalContact: 'Principal Contact',
    playerInfo: 'Player Info',
    plays: 'Plays',
    primaryAlert: 'Primary Alert',
    primaryContact: 'Primary Contact',
    primaryUser: 'Primary User',
    primepayGateway: 'Primepay Gateway',
    product: 'Product',
    profit: 'Profit',
    progress: 'Progress',
    promo: 'Promo',
    promos: 'Promos',
    promotion: 'Promotion',
    promotions: 'Promotions',
    pullTabs: 'Pull Tabs',
    purchase: 'Purchase',
    quarterToDate: 'Quarter To Date',
    read: 'Read',
    reboot: 'Reboot',
    receiptData: 'Receipt Data',
    recycler: 'Recycler',
    recyclers: 'Recyclers',
    redemption: 'Redemption',
    reference: 'Reference',
    refund: 'Refund',
    refunds: 'Refunds',
    refSerial: 'Reference #',
    releaseEscrow: 'Release Escrow',
    remove: 'Remove',
    reports: 'Reports',
    resetPOSAdminPassword: 'Reset POS Admin Password',
    restore: 'Restore',
    results: 'Results',
    reversal: 'Reversal',
    role: 'Role',
    roleName: 'Role Name',
    roleToAdd: 'Role To Add',
    roles: 'Roles',
    rtp: 'RTP',
    salesAgent: 'Sales Agent',
    salesCommission: 'Sales Commission',
    save: 'Save',
    saveChanges: 'Save Changes',
    search: 'Search',
    secondaryAlert: 'Secondary Alert',
    secondaryContact: 'Secondary Contact',
    securityCheck: 'Security Check',
    select: 'Select',
    selectCompany: 'Select Company',
    selectOne: 'Select One',
    selectDefaultRole: 'Select a Default Role',
    selectFeatures: 'Select Features',
    selectLocation: 'Select Location',
    selectTableColumns: 'Select Table Columns',
    selectUser: 'Select a User',
    selectUserRole: 'Select a User or a User Role',
    selectOption: 'Select an option:',
    selectVendor: 'Select Vendor',
    selectedReport: 'Selected Report',
    send: 'Send',
    sendCommand: 'Send Command',
    sendThirdPartyIP: 'Send 3rd Party IP',
    sendLogs: 'Send Logs',
    sequence: 'Sequence',
    serialNumber: 'Serial Number',
    serviceEvents: 'Service Events',
    serviceItemAd: 'Interested? Contact Primero to learn more.',
    serviceTypes: 'Service Types',
    services: 'Services',
    settings: 'Settings',
    setLocation: 'Set Location',
    setLocationsModalTitle: 'Set location of selected devices:',
    setPassword: 'Set Password',
    settingsGeneral: 'General Settings',
    settingsLists: 'Lists',
    settingsManifests: 'Manifests',
    settingsPermissions: 'Permissions',
    settingsSources: 'Sources',
    setStatusTo: 'Set status to:',
    shareRate: 'Share Rate',
    show: 'Show',
    showAdminPassword: 'Show Admin Password',
    showBetRates: 'Show Bet Rates',
    showPassword: 'Show Password',
    showPin: 'Show PIN',
    source: 'Source',
    sources: 'Sources',
    sourceType: 'Source Type',
    subtype: 'Subtype',
    suite: 'Suite',
    sourceTypes: 'Source Types',
    spinRate: 'Spin Rate',
    stacker: 'Stacker',
    standard: 'Standard',
    startDate: 'Start Date',
    startService: 'Start Service',
    state: 'State',
    status: 'Status',
    submit: 'Submit',
    subtotal: 'Subtotal',
    subtotals: 'Subtotals',
    super: 'Super',
    superUser: 'Super User',
    supervisor: 'Supervisor',
    supervisorId: 'Supervisor ID',
    sweepsKiosk: 'Sweeps Kiosk',
    targetDateTime: 'Target Date Time',
    targetVersion: 'Target Version',
    terminal: 'Device',
    terminalId: 'Device ID',
    terminalStatus: 'Device Status',
    terminalSubtype: 'Device Subtype',
    terminalType: 'Device Type',
    terminalTypeDescription: 'Device Type Description',
    territory: 'Territory',
    thirdPartyIP: '3rd Party IP Address',
    thisWeek: 'This Week',
    ticketIn: 'Ticket In',
    ticketOut: 'Ticket Out',
    timezones: 'Time Zones',
    title: 'Title',
    today: 'Today',
    topWin: 'Top Win',
    total: 'Total',
    totals: 'Totals',
    totalWins: 'Total Wins',
    totalsAverages: 'Totals/Averages',
    transactions: 'Transactions',
    transactionId: 'Transaction ID',
    transactionType: 'Transaction Type',
    transactionTypes: 'Transaction Types',
    true: 'True',
    truepointPOS: 'TruePoint POS',
    trueRouteId: 'TrueRoute ID',
    type: 'Type',
    types: 'Types',
    typeNotFound: 'Error: No device of this type found',
    unassign: 'Unassign',
    undo: 'Undo',
    unknown: 'Unknown',
    update: 'Update',
    updateColumns: 'Update Columns',
    updateComplete: 'Update Complete',
    updateFailed: 'Update Failed',
    updateInfo: 'Update Info',
    updateLocations: 'Update Locations',
    updateRole: 'Update Role',
    updateSource: 'Update Source',
    updateSourceType: 'Update Source Type',
    updateStream: 'Update Stream',
    updateSubtype: 'Update Subtype',
    updateUserLocations: 'Update User Locations',
    url: 'URL',
    useGenericSymbols:'Use Generic Symbols',
    useMatch2:'Use Match 2',
    useMegawaysBranding:'Use Megaways Branding',
    usePaybackPuzzle:'Use Payback Puzzle',
    user: 'User',
    users: 'Users',
    usersAssignedRole: 'Users Assigned this Role',
    username: 'Username',
    userCompanies: 'User Companies',
    userId: 'User ID',
    userProfile: 'User Profile',
    userPermissions: 'User Permissions',
    userPermissionsInstructions: 'Collector accounts are only able to view information on machines, locations, and events they are associated with, and can only update their own password. To allow all collectors to view all locations use this toggle:',
    userRoles: 'User Roles',
    version: 'Version',
    view: 'View',
    vtp: 'VTP',
    websocketUrl: 'Websocket URL',
    warning: 'Warning',
    warningForDeleteManifest: 'Are you sure you want to delete this manifest?',
    warningForDeleteSource: 'Are you sure you want to delete this source?',
    warningForSameLocation: 'Source location can not be the same as destination one',
    winCount: 'Win Count',
    wins: 'Wins',
    write: 'Write',
    yesterday: 'Yesterday',
    yourCompany: 'Your Company',
    yourEmail: 'Your Email',
    yourProfile: 'Your Profile',
    yearToDate: 'Year to Date ',
    zip: 'Zip',
    defaultRowCount: 10,
    rowCounts: [10, 20, 50, 100]
}

export const capText = {
    deleteAccounts: 'DELETE ACCOUNTS',
    pin: 'PIN',
    warning: 'WARNING',
}

export const ROLES = {
    admin: 5150,
    superUser: 3300,
    user: 2001
}
