import { text as t } from 'shared/text'

export const apiLogsLayout = {
    hasDateRange: true,

    defaultSort: {
        desc: true,
        orderBy: 'createdOn'
    },

    tableHeaders: {
        id: t.id,
        callerId: t.callerId,
        url: t.url,
        createdOn: t.createdOn,
        headers: t.headers
    },

    sizes: {
        id: 'small',
        callerId: 'small',
        url: 'mid',
        createdOn: 'mid',
        headers: 'large'
    },

    layouts:  {
        headers: 'text-left',
        url: 'text-right'
    },

    formats: {
        createdOn: 'standard-date'
    },
    
    tooltips: {
        headers: {prop: 'headers'}
    }
}