import './cardSection.scss';
const CardSection = ({classes, label, children, labelBarAddition}) => {
    return (
        <div className={`card-section ${classes ? classes : ''}`}>
            {label && <div className='card-section-label-bar'>
                <div className={`card-section-label ${labelBarAddition ? 'half' : 'full'}`}>{label}</div>
                <div> {labelBarAddition} </div>
            </div>}
            <div className='card-section-content grid'>
                {children}
            </div>
        </div>
    )
}

export default CardSection;