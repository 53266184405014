import './sources.scss';
import { useEffect, useRef, useState } from 'react';
import { sourcesLayout as layout } from 'layouts/Sources/SourcesLayout';
import useUtils from 'hooks/useUtils';
import useProfile from 'hooks/useProfile';
import Page from 'app-components/Page/Page';
import Table from 'app-components/Table/Table/Table';

const Sources = () => {
    const { sources, setSources } = useProfile();
    const { callAPI } = useUtils();
    const [ tableLoading, setTableLoading ] = useState([true]);
    const runOnce = useRef(false);

    useEffect(() => {
        if (runOnce.current) return;
        runOnce.current = true;
        const params = { updateSourceId: null }
        callAPI('sources', sourcesCallback, params);
    }, [])

    const sourcesCallback = (data) =>{
        setSources(data?.tableData ? data.tableData : []);
        setTableLoading([false]);
    }

    return (
        <Page page={layout.page}>
            <Table 
                data={sources}
                layout={layout} 
                context={useProfile}
                loading={tableLoading}
            />
        </Page>
    )
}

export default Sources;