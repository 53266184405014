import './contextMenu.scss';
import { useEffect, useState, useMemo } from 'react';
import { text as t } from 'shared/text';
import useProfile from 'hooks/useProfile';
import ContextDropdown from './ContextDropdown/ContextDropdown';

const ContextMenu = ({layout}) => {
    const { tableStates } = useProfile();
    const [ disableButton, setDisableButton ] = useState(true);
    const [ showSpinner, setShowSpinner ] = useState (false);

    const id = layout.id;
    const state = useMemo(()=> tableStates?.[id], [tableStates?.[id]]);

    useEffect(()=>{
        if((state?.multiCheck?.length > 0) || state?.selected) {
            setDisableButton(false)
        } else {
            setDisableButton(true);
        }
    },[state?.multiCheck, state?.selected]);

    const onContextButtonClick = (event) => {
        const target = event.currentTarget;
        const parent = target.parentElement;
        const classList = parent.classList;
        if (classList.contains('open')) {
            classList.remove('open');
        } else {
            classList.add('open');
            const menu = parent.getElementsByClassName('context-menu')[0];
            const targetHeight = target.offsetHeight; // Width of menu + width of button + 1 to ensure complete coverage;
            const left = target.offsetLeft;
            const top = target.offsetTop;
            const screenHeight = window.innerHeight;
            const menuHeight = parent.children[1].offsetHeight;
            if (screenHeight - top < menuHeight) {
                menu.style.top = top - menuHeight + targetHeight + 'px';
            } else {
                menu.style.top = top + targetHeight + 'px';
            }
            if (window.innerWidth < 500) {
                menu.style.left = left  + 'px';
            } else {
                menu.style.left = left - 230 + target.offsetWidth + 'px';
            }
        }

    }

    const onContextExit =(event) => {
        closeContext(event)
    }

    const closeContext = (event) => {
        event.currentTarget.classList.remove('open');
    }

    return <div className='context-menu-container' onMouseLeave= {(event)=>{onContextExit(event)}}>
        <div id='context-button'className={`context-button ${disableButton? 'disabled' : ''}`} onClick={(event)=>{onContextButtonClick(event)}}>
            {showSpinner ? <span className='spinner'/> : t.actions}
        </div>
        <div className='context-menu'>
            <ContextDropdown menu={true} layout={layout} pageId={id} setLoading={setShowSpinner}/>
        </div>
    </div>
}

export default ContextMenu;
