import './deviceKiosk.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { deviceKioskLayout as layout } from 'layouts/Devices/DeviceDetails/DeviceKioskLayout';
import { deepCopy, getDefaultDateRange, getAPIDates } from 'components/shared/componentUtils';
import { getFinanceData } from 'shared/utils';
import { text as t } from 'shared/text';
import * as e from 'shared/excel';
import useProfile from 'hooks/useProfile';
import useTables from 'components/Tables/TableParts/TableHooks/useTables'
import useUtils from 'hooks/useUtils';
import Button from 'components/Button/Button';
import Card from 'app-components/Cards/Card/Card';
import CashDisplay from 'components/CashDisplay/CashDisplay';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import FinanceDisplay from 'components/FinanceDisplay/FinanceDisplay';
import TableListed from 'components/Tables/TableListed';

const DeviceKiosk = () => {
    const { id } = useParams();
    const { setHeader, updateDeviceBreadcrumbs } = useProfile();
    const { tableData, tableTotals,
            getTableColumns, updateTable } = useTables();
    const { callAPIGet } = useUtils();
    const [ pageData, setPageData ] = useState();
    const [ tableRecords, setTableRecords ] = useState();
    const [ deviceLocation, setDeviceLocation ] = useState();
    const [ defaultDates, setDefaultDates ] = useState();
    const [ currentRange, setCurrentRange ] = useState();
    const [ financials, setFinancials ] = useState();
    const [ cashBreakdown, setCashBreakdown ] = useState();
    const [ masked, setMasked ] = useState([true]);
    const deviceType = useRef();
    const tableId = 'device-kiosk-' + id;

    useEffect(() => {
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        setCurrentRange(range);
        callAPIGet('deviceDetails', deviceDetailsCallback, {terminalId: id}, range);
    }, []);

    const deviceDetailsCallback = (data, range) => {
        const type = data.terminalType;
        setPageData(data);
        setHeader(type ? type.name + ': ' + data.terminalId : data.terminalId);
        updateDeviceBreadcrumbs(data);
        deviceType.current = type?.id;
        const locationId = data?.location?.id
        setDeviceLocation(locationId);
        getReportData(range, locationId);
    }

    const getReportData = (range, locationId) => {
        setMasked([true]);
        updateTable('dates', tableId, range);
        const params = getAPIDates(range);
        params.locationId = deviceLocation ? deviceLocation : locationId;
        callAPIGet('report-LocationGameroom', deviceKiosksCallback, params);
    }

    const deviceKiosksCallback = (data) => {
        setMasked([false])
        const records = data?.data ? data.data : [];
        setTableRecords(records);
        setFinancials(getFinanceData([deviceType.current], data?.totalFinancialSummary, records ))
        setCashBreakdown(data?.totalCashBreakdown? data.totalCashBreakdown: null);
    }

    const onDateRangePick = (range) => {
        setMasked([true])
        getReportData(range);
    }

    const onExportToExcel = () => {
        const dateInfo = {
            type: 'table',
            columns: ['deviceId', 'startDate', 'endDate', 'locationName'],
            headers: {
                deviceId: t.deviceId,
                startDate: t.startDate,
                endDate: t.endDate,
                locationName: t.locationName,
            },
            data:[{
                deviceId: id,
                startDate: e.getExcelDate(currentRange[0]),
                endDate: e.getExcelDate(currentRange[1]),
                locationName: pageData?.location?.label,
            }],
            configs: {
                formats: {locationName: 'text-wrap'}
            }
        };

        const financeInfo = e.getExcelFinancials(financials);

        const cashInfo = cashBreakdown ? e.getExcelCash(cashBreakdown) : null;

        const tableRowData = deepCopy(tableData[tableId]);
        if (layout.subtotals){
            tableRowData.push({totals: tableTotals[tableId]});
        }
        const columns = getTableColumns(tableId, layout);
        const tableInfo = e.getExcelTable( t.gameDetails, columns, tableRowData, layout);
        const infoArray = cashBreakdown ? [dateInfo, financeInfo, cashInfo, tableInfo] : [dateInfo, financeInfo, tableInfo]
        const sizes = columns.map(key => layout.sizes[key]);
        e.exportToFile(id + ' Device Kiosk Report', infoArray, sizes)
    }

    return (
        <div className='full grid'>
        <Card hideLabel={true} classes='full'>
            <DateRangePicker
                classes='quarter'
                defaultVal={defaultDates}
                label={t.dateRange}
                disabled={masked?.[0]}
                callback={onDateRangePick}
            />
            <Button
                classes='green sixth match-labeled-input'
                disabled={masked?.[0]}
                onClick={onExportToExcel}
            >{t.exportToExcel}</Button>
        </Card>
        <Card label={t.financials} classes='third margin-top' masked={masked?.[0]}>
            <FinanceDisplay types={[deviceType.current]} classes='full' data={financials?.data} hideTitle={true}/>
        </Card>
        {cashBreakdown && <Card label={t.cashBreakdown} classes='third margin-top' masked={masked?.[0]}>
            <CashDisplay classes='full' data={cashBreakdown} />
        </Card>}
        <div className='location-gameroom-table-title full margin-top'>{t.gameDetails}</div>
        <TableListed
            id={tableId}
            classes='full'
            data={tableRecords}
            layout={layout}
            masked={masked?.[0]}
        />
    </div>)
}

export default DeviceKiosk;
