import './locationTransactions.scss';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getDefaultDateRange, getAPIDates } from 'components/shared/componentUtils';
import { locationTransactionsLayout as layout } from 'layouts/Locations/LocationTransactionsLayout';
import { text as t } from 'shared/text';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/useProfile';
import useTables from 'components/Tables/TableParts/TableHooks/useTables';
import useUtils from 'hooks/useUtils';
import DateRangePicker from 'components/DateRangePicker/DateRangePicker';
import Dropdown from 'components/Dropdown/Dropdown';
import TableListed from 'components/Tables/TableListed';

const LocationTransactions = () => {
    const { id } = useParams();
    const { updateDropdown } = useInputs();
    const { updateLocationBreadcrumbs, updateLocationHeader } = useProfile();
    const { updateTable, updateDropdowns, getURLParams } = useTables();
    const { callAPI, callAPIGet } = useUtils();
    const [ devices, setDevices ] = useState();
    const [ tableRecords, setTableRecords ] = useState();
    const [ masked, setMasked ] = useState([true]);
    const [ defaultDates, setDefaultDates ] = useState();
    const tableId = 'location-transactions-' + id;
    const deviceId = tableId + '-deviceId';

    useEffect(() => {
        const range = getDefaultDateRange(tableId);
        setDefaultDates(range);
        getReportData(range);
        callAPI('locationDevices', locationDevicesCallback, {locationId: id});
        callAPI('locationDetails', locationDetailsCallback, {id: id});
    }, []);

    useEffect(() => {
        const urlParams = getURLParams(tableId);
        if (urlParams.dropdowns) {
            const sorts = urlParams.dropdowns?.[deviceId];
            sorts && updateDropdown(deviceId, sorts.value, sorts.label);
        }
    }, [devices])

    const getReportData = (range) => {
        setMasked([true]);
        updateTable('dates', tableId, range);
        const params = getAPIDates(range);
        params.locationId = id;
        callAPIGet('report-LocationTransactions', locationTransactionsCallback, params);
    }

    const locationTransactionsCallback = (data) => {
        setTableRecords(data.tableData ? data.tableData : []);
        setMasked([false]);
    }

    const locationDetailsCallback = (data) => {
        const details = data?.locationDetails;
        updateLocationBreadcrumbs(details);
        details && updateLocationHeader(details);
    }

    const locationDevicesCallback = (data) => {
        setDevices(data?.data ? data.data : []);
    }

    const onDateRangePick = (range) => {
        getReportData(range);
    }

    const onDeviceTypeSelect = (data) => {
        data.searchData = {
            dropdownProp: 'value',
            recordProp: 'terminalId'
        }
        updateDropdowns(tableId, deviceId, data);
    }

    return (<div className='full grid'>
        <TableListed
            id={tableId}
            classes='full'
            data={tableRecords}
            layout={layout}
            masked={masked?.[0]}
            searchBarContent={<>
                <Dropdown
                    id={deviceId}
                    classes='quarter'
                    label={t.devices}
                    data={devices}
                    disabled= {devices?.[0] ? false : true}
                    masked={masked?.[0]}
                    appendProp='type'
                    nullable={true}
                    hideErrors={true}
                    callback={onDeviceTypeSelect}/>
                <DateRangePicker
                    classes='quarter'
                    defaultVal={defaultDates}
                    label={t.dateRange}
                    disabled={masked?.[0]}
                    callback={onDateRangePick}
                />
            </>}
        />
    </div>)
}

export default LocationTransactions;
