import './modal.scss'
const Modal = ({classes, title, contentClasses, children}) => {
    return (
        <div className={`modal-backdrop ${classes ? classes : ''}`}>
            <div className={`modal-container`}>
                <div className='modal-title'>{title}</div>
                <div className={`modal-content grid ${contentClasses ? contentClasses : ''}`}>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default Modal;