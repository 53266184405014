import { createContext, useState } from 'react';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
    const [ auth, setAuth ] = useState();
    const [ loggedIn, setLoggedIn ] = useState();
    const [ logoutTimer, setLogoutTimer ]  = useState();

    return (
        <AuthContext.Provider value={{ 
            auth, setAuth,
            loggedIn, setLoggedIn,
            logoutTimer, setLogoutTimer,
        }}>
            {children}
        </AuthContext.Provider>
    )
}

export default AuthContext;
