import { createContext, useState } from 'react';

const TablesContext = createContext({});

export const TablesProvider = ({ children }) => {
    const [ tableData, setTableData ] = useState();
    const [ tableTotals, setTableTotals ] = useState();
    const [ tableColumnsRemoved, setTableColumnsRemoved ] = useState();
    const [ tableSearches, setTableSearches ] = useState();
    const [ tableSorts, setTableSorts ] = useState();
    const [ tableDropdowns, setTableDropdowns ] = useState();
    const [ tableDates, setTableDates ] = useState();
    const [ tableToggles, setTableToggles ] = useState();
    const [ tableSelectedRows, setTableSelectedRows ] = useState();
    const [ tableCheckAlls, setTableCheckAlls ] = useState();
    const [ tableChecks, setTableChecks ] = useState();
    const [ tableRowCounts, setTableRowCounts ] = useState();
    const [ tablePageCounts, setTablePageCounts ] = useState();
    const [ tableCurrentPages, setTableCurrentPages ] = useState();

    return (
        <TablesContext.Provider value={{ 
            tableData, setTableData,
            tableTotals, setTableTotals,
            tableSearches, setTableSearches,
            tableColumnsRemoved, setTableColumnsRemoved,
            tableSorts, setTableSorts,
            tableDropdowns, setTableDropdowns,
            tableDates, setTableDates,
            tableToggles, setTableToggles,
            tableSelectedRows, setTableSelectedRows,
            tableCheckAlls, setTableCheckAlls,
            tableChecks, setTableChecks,
            tableRowCounts, setTableRowCounts,
            tablePageCounts, setTablePageCounts,
            tableCurrentPages, setTableCurrentPages,
        }}>
            {children}
        </TablesContext.Provider>
    )
}

export default TablesContext;