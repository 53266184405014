import './deviceReports.scss';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { text as t } from 'shared/text';
import useComponents from 'components/hooks/Components/useComponents';
import useInputs from 'components/hooks/Inputs/useInputs';
import useProfile from 'hooks/useProfile';
import useUtils from 'hooks/useUtils';
import Card from 'app-components/Cards/Card/Card';
import Dropdown from 'components/Dropdown/Dropdown';
import Page from 'app-components/Page/Page';
import DeviceTransactions from './DeviceTransactions/DeviceTransactions';
import DeviceGames from './DeviceGames/DeviceGames';
import DeviceKiosk from './DeviceKiosk/DeviceKiosk';

const deviceReportsData = {
    general: [
        {value: 'transactions', label: t.transactions},
    ],
    piggyBank: [
        {value: 'transactions', label: t.transactions},
        {value: 'games', label: t.gamePerformance},
    ],
    grc: [
        {value: 'transactions', label: t.transactions},
        {value: 'kiosk', label: t.kiosk}
    ]
}

const deviceReports = {
    'transactions': <DeviceTransactions/>,
    'games': <DeviceGames/>,
    'kiosk': <DeviceKiosk/>,
}

const DeviceReports = () => {
    const { id, type } = useParams();
    const { navTo } = useComponents();
    const { updateDropdown } = useInputs();
    const { deviceTabs } = useProfile();
    const { callAPIGet, updateDeviceTabs } = useUtils();
    const [ tabType, setTabType ] = useState();
    const [ reportsList, setReportsList ] = useState();
    const [ currentReport, setCurrentReport ] = useState();

    useEffect(() => {   
        if (!currentReport && type) {
            updateDropdown('device-reports', type, getDropdownLabel(type));
        }
    }, [])

    useEffect(() => {
        const params = {terminalId: id}
        callAPIGet('deviceDetails', deviceDetailsCallback, params);
        updateDropdown('device-reports', type, getDropdownLabel(type));
        !currentReport && setCurrentReport(type ? type : 'transactions');
    }, [type])

    useEffect(() => {
        if (currentReport && type !== currentReport) {
            navTo(`/device/${id}/reports/${currentReport}`);
        }
    }, [currentReport]);

    useEffect(() => {
        if (!tabType) {return};
        if (tabType.id === 3) {
            setReportsList(deviceReportsData.piggyBank)
        } else if (tabType.id === 2) {
            setReportsList(deviceReportsData.grc)
        } else if (tabType.id !== 5) {
            setReportsList(deviceReportsData.general)
        }
    }, [tabType])

    const deviceDetailsCallback = (data) => {
        const type = data?.terminalType;
        updateDeviceTabs(type.id)
        setTabType(type);
    }

    const onReportSelect = (data) => {
        setCurrentReport(data.value)
    }

    const getDropdownLabel = (val) => {
        switch(val) {
            case 'transactions':
                return t.transactions;
            case 'games':
                return t.gamePerformance;
            case 'kiosk':
                return t.kiosk;
            // case 'jackpots':
            //     return t.jackpots;
            default:
                return t.transactions;
        }
    }


    return (
        <Page
            subPage={t.reports}
            tabs={deviceTabs.tabs}
            contentClasses='grid device-reports'
        >
            <Card hideLabel={true} classes='full grid margin-bottom'>
                <Dropdown
                    id='device-reports'
                    classes='quarter'
                    label={t.selectedReport}
                    noPermission={reportsList?.length <= 1}
                    hideErrors={true}
                    data={reportsList}
                    callback={onReportSelect}/>
            </Card>
            {deviceReports[currentReport]}
        </Page>
    )
}

export default DeviceReports;
