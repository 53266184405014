import './tableHeaders.scss';
import { ifElse } from 'components/shared/componentUtils';
import useTable from '../TableHooks/useTables';
import TableHeaderCell from './TableHeaderCell/TableHeaderCell';

const TableHeaders = ({id, layout, noURL}) => {
    const { getTableColumns } = useTable();

    const buildHeaderRow = () => {
        if (!layout) {return null}
        const columns = getTableColumns(id, layout)
        const headers = ifElse(layout?.tableHeaders);
        const cells = []; 
        columns.forEach((prop, i) => {
            const header = headers[prop];
            if (Array.isArray(header)) {
                header.forEach((label, j)=>{
                    cells.push(<TableHeaderCell key={`${id}-${prop}-${i}-${j}`} id={id} layout={layout} label={label} prop={prop} noURL={noURL}/>);
                })
            } else {
                cells.push(<TableHeaderCell key={`${id}-${prop}-${i}`} id={id} layout={layout} label={header} prop={prop} noURL={noURL}/>);
            }
        });
        return cells; 
    }

    return (
        <div className={`table-header-row ${layout?.hideSearch ? 'top' : ''}`}>
            {buildHeaderRow()}
        </div>
    );
}

export default TableHeaders;
