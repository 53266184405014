import './menuItem.scss';
import { useLocation } from 'react-router-dom';
import useComponents from 'components/hooks/Components/useComponents';
import useProfile from 'hooks/useProfile';

const MenuItem = ({icon, label, path, classes, buildMenuItem, callback, children}) => {
    const { navTo } = useComponents();
    const { size, setTableStates } = useProfile();

    const location = useLocation();

    const onItemHover = (event) => {
        if (!children || size === 'phone') {return};
        const menu = event.currentTarget.children[1];
        if (menu) {
            const target = event.currentTarget;
            const rightBound = target.getBoundingClientRect().right;
            const distanceFromRight = window.innerWidth - rightBound - 1; // -1 for border
            menu.style.right = `${distanceFromRight}px`;
            menu.style.maxHeight = '400px';
        }
    }

    const onItemExit = (event) => {
        if (!children || size === 'phone') {return};
        const menu = event.currentTarget.children[1];
        if (menu) {
            menu.style.maxHeight = '0px';
        }
    }

    const onMenuItemClick = (event, children) => {
        if (children) {
            size === 'phone' && onPhoneMenuItemClick(event, children);
            return;
        };
        event.preventDefault();
        const dropdown = event.currentTarget.closest('.menu-item-children-menu');
        if (dropdown) {
            dropdown.style.maxHeight = '0';
            setTimeout(() => {
                dropdown.style.cssText = ''; 
            }, 300);
        }
        if (callback) {
            setTableStates({});
            callback();
            return;
        }
        setTableStates({});
        if (path === location.pathname) {
            navTo('/temp-path', ['replace']);
            setTimeout(() => {
                navTo(path, ['replace']);
            }, 0);
        } else {
            navTo(path);
        }
    };

    const onPhoneMenuItemClick = (event) => {
        const container = event.currentTarget;
        if (container.classList.contains('open')) {
            container.classList.remove('open')
        } else {
            const opened = container.closest('#main-menu').getElementsByClassName('open');
            opened.length > 0 && opened[0].classList.remove('open');
            container.classList.add('open');
        }
    }
    return (
        <div className= {`menu-item-container ${classes ? classes : ''}`}             
            onMouseEnter={onItemHover} 
            onMouseLeave={onItemExit} 
            onClick={(event)=>{onMenuItemClick(event, children)}}
        >
            <a className='menu-item' href={path}>
                {icon && <i className={`menu-item-icon ${icon}`}></i>}
                {label && <div className='menu-item-label'>{label}</div>}
            </a>
            {children && <div className='menu-item-children-menu'>
                {children.map((child, i) =>{
                    return buildMenuItem(child, i);
                })}
            </div>}
        </div>

    );
}

export default MenuItem;