import './deleteAccountsModal.scss';
import { useEffect, useRef, useState } from 'react';
import { toPin } from 'components/shared/componentUtils';
import { text as t, capText as c } from 'shared/text';
import useModal from 'components/hooks/Modal/useModal';
import useUtils from 'hooks/useUtils';
import useProfile from 'hooks/useProfile';
import Button from 'components/Button/Button';
import Modal from 'components/Modal/Modal';

const DeleteAccountsModal = ({pins, phone, callback}) => {
    const { removeModal } = useModal();
    const { setFreeze } = useProfile();
    const { callAPI, updateNotice } = useUtils();
    const [ deleteDone, setDeleteDone ] = useState(false);
    const totalCount = useRef(0);

    useEffect(()=> {
        if (totalCount.current === pins?.length) {
            setDeleteDone(true);
            setFreeze([false]);
        }
    }, [totalCount.current])

    const onDeleteConfirm = (event, setLoading) => {
        setLoading(true);
        setFreeze([true]);
        const params = {
            phoneNumber: phone,
            pinNumbers: pins,
        };
        const props = {
            event: event,
            setLoading: setLoading,
            pins: pins
        }
        callAPI('accountsDelete', accountsDeleteCallback, params, props);
    }

    const accountsDeleteCallback = (data, props) => {
        props.setLoading(false);
        setFreeze([false]);
        setDeleteDone(true);
        const pins = props.pins;
        pins.forEach((pin)=>{
            const el = document.getElementById(pin + '-result');
            if (data.isSuccessful) {
                el.classList.add('delete-successful');
                el.innerHTML = t.deleted;
            } else {
                el.classList.add('delete-failed');
                el.innerHTML = t.error + ': ' + data.errorMessage;
            }
        })
    }

    const onCancel = () => {
        removeModal();
    }

    const onSubmit = (event, setLoading) => {
        setLoading(true);
        callAPI('accountSearchByPhone', accountSearchByPhoneCallback, {phoneNumber: phone}, setLoading)
    }

    const accountSearchByPhoneCallback = (data, setLoading) => {
        setLoading(false);
        if (data.isSuccessful) {
            callback(data?.matches ? data.matches : []);
            removeModal();
        } else {
            updateNotice(data);
        }
    }

    return (
        <Modal classes={'delete-accounts-modal'}>
            <div className='grid'>
                <div className='delete-accounts-modal-warning full '>{t.deleteAccountsWarning}</div>
                <div className='delete-accounts-modal-header third'>{c.pin}</div>
                <div className='delete-accounts-modal-header two-thirds'>{t.results}</div>
                <div className='delete-accounts-container full grid'>
                    {pins && pins.map((pin, i) => {
                        return <div key={i} className='delete-accounts-user-line full grid'>
                            <div className='delete-accounts-modal-label third'>{toPin(pin)}</div>
                            <div id={pin + '-result'} className='delete-accounts-modal-result two-thirds'>?</div>
                        </div>
                    })}
                </div>
                {!deleteDone ? <>
                    <Button
                        classes='red half delete-accounts-modal-confirm' 
                        type='submit' 
                        onClick={(event, setLoading) => {onDeleteConfirm(event, setLoading)}}
                    >{t.delete}</Button>
                    <Button classes='blue half delete-accounts-modal-cancel' onClick={onCancel}>{t.cancel}</Button>
                </>
                :<Button
                    classes='green centered half delete-accounts-modal-confirm' 
                    type='submit' 
                    onClick={(event, setLoading) => {onSubmit(event, setLoading)}}
                >{t.confirm}</Button>}
            </div>
        </Modal>
    )
}

export default DeleteAccountsModal;