import './input.scss'
import { useEffect, useRef, useState } from 'react';
import { isThen, ifElse, controlForPercentage,
         keyType, isValid, 
         getFormId, getInputType } from '../shared/componentUtils';
import { getMaxLength } from '../shared/componentUtils';
import useInputs from '../hooks/Inputs/useInputs';
import Icon from '../Icon/Icon';

const Input = ({id, section, 
                line, index,
                classes, type, 
                label, hideLabel,
                placeholder, disabled,
                startFocused, dropdown, 
                hideErrors, blockBlur,
                masked, maxLength,
                noPermission, selectOnClick, 
                onDropdownAutofill,
                hideCover, onClickCallback,
                onBlurCallback, callback}) => {
    const { inputValues, updateInput, 
            dropdownDisplays, currentInput, 
            inputErrors, touchscreen, 
            changeTouchScreenState, changeCurrentInput, 
            getInputDisplay, clearError} = useInputs();
    const [ fieldType, setFieldType ] = useState(type === 'password' ? 'password' : 'text');
    const [ maskState, setMaskState ] = useState(masked ? masked : '');
    const [ displayValue, setDisplayValue ] = useState();
    const [ hideDisplay, setHideDisplay ] = useState(false);
    id = line ? getFormId(section, line, index) : id;
    const inputType = useRef(getInputType(type))
    const currentVal = useRef();
    const firstClick = useRef(true);
    const autofilled = useRef(false);
    const max = getMaxLength(type, maxLength);

    useEffect(() => {
        const el = document.getElementById(id);
        const handleAutoFill = (event) => {
            if (event.animationName === 'autofill') {
                autofilled.current = true;
            }
        };
        el.addEventListener('animationstart', handleAutoFill);
        return () => {
            el.removeEventListener('animationstart', handleAutoFill);
        };
    }, [id]);

    const noCover = () => {
        if (fieldType === 'password' || 
            fieldType === 'password-revealed' || 
            type === 'grc-pin' ||
            type === 'text' || 
            type === 'email' ||
            hideCover) {
                return true
        } else {
            return false;
        }
    }
    const removeCover = (dropdown || noCover()) ? true : false;

    useEffect(()=>{
        startFocused && document.getElementById(id).focus();
    }, [startFocused]);

    useEffect(() => {
        if (!masked) {
            setMaskState('fading-pseudo');
            setTimeout(() => {
                setMaskState('fading-mask');
                setTimeout(() => {
                    setMaskState('faded');
                }, 300);
            }, 100); 
        } else {
            setMaskState(false);
        }
    }, [masked]);

    useEffect(()=>{
        const value = inputValues?.[id];
        if (isValid(value) && value !== currentVal.current && !dropdown) {
            const display = getInputDisplay(inputValues[id].toString(), type);
            currentVal.current = value;
            setDisplayValue(display);
        } else if (value === undefined) {
            currentVal.current = '';
            setDisplayValue('');
        }
    }, [currentVal, inputValues]);

    const onFocus = (event) => {
        event.preventDefault();
        setHideDisplay(true);
        const el = document.getElementById(id);
        if (selectOnClick && firstClick.current) {
            firstClick.current = false;
            el.select();
        };
        changeCurrentInput(id);
        setTimeout(()=>{
            el.focus();
        }, 0);
        onClickCallback && onClickCallback(event);
    }

    const onKeyDown = (event) => {
        const {key, target, ctrlKey} = event;
        const length = target.value.length;
        if (keyType(key, 'skipList')) {return};
        if (ctrlKey && keyType(key, 'ctrl')) {return};
        if (type === 'percentage' || type === 'max100') {
            controlForPercentage(event, key, inputValues[id]);
        } else if (inputType.current === 'number' && isNaN(key)) {
            event.preventDefault();
        }
        if (length >= max && !isHighlighted(target)) {
            event.preventDefault();
        } 
    }

    const onChange = (event) => {
        clearError(id);
        let val = event.target.value;
        if ((type === 'percentage'|| type === 'max100')) {
            if (val > 100) {
                val = '100'
            } else if (val.startsWith('0')) {
                val = val.replace(/^0+/, '');
            }
        }

        if (dropdown && autofilled.current) {
            onDropdownAutofill(val);
            autofilled.current = false;
        } else {
            updateInput(id, val, dropdown);
        }
        if (callback)  {
            callback({
                section: section,
                line: line,
                id: id,
                value: val
            });
        }
    };

    const onBlur = () => {
        setHideDisplay(false);
        firstClick.current = true;
        if (blockBlur || touchscreen) {return};
        if (id === currentInput) {changeTouchScreenState(null)};
        if (inputValues?.[id]) {
            const display = getInputDisplay(inputValues?.[id].toString(), type);
            setDisplayValue(display);
        } else if ((type === 'percentage' || type === 'max100') && !inputValues?.[id]) {
            setDisplayValue('0');
            updateInput(id, '0', dropdown);
        } else {
            setDisplayValue('');
            currentVal.current = '';
        }

        onBlurCallback && onBlurCallback(
            {
                section: section,
                line: line,
                id: id,
                value: inputValues[id]
            }
        )
    }

    const onPasswordReveal = () => {
        setFieldType('password-revealed');
    }

    const onPasswordHide = () => {
        setFieldType('password');
    }

    const isHighlighted = (target) => {
        return target.selectionStar !== target.selectionEnd;
    }

    return (
        <div id={`${id}-input`}
            className={[
            "input-container", classes || '',
            hideLabel ? 'hide-label' : '',
            hideErrors ? 'hide-errors' : '',
            disabled ? 'disabled' : '',
            isThen(inputErrors?.[id], 'error'),
            (inputType.current === 'number' && type !== 'phone' && type !== 'zip') ? 'right' : '',
            noPermission ? 'display-only' : '',
            masked ? 'masked' : ''
        ].join(' ')}>
            <div className={`input-label`}>{label}</div>
            <div className={`input-mask ${maskState}`}/> 
            <div className= {`input-cover-display ${(placeholder && !displayValue) ? 'placeholder' : ''}`} onClick={(event)=>{onFocus(event)}}>
                {displayValue ? displayValue : placeholder ? placeholder : ''}{type === 'percentage' ? '%' : ''}
            </div>
            <input
                id={id}
                className={`input-display 
                    ${(hideDisplay || removeCover)? 'no-cover' : ''}
                    ${type === 'allCaps' ? 'allCaps' : ''} ${!isValid(inputValues?.[id] || !isValid(dropdownDisplays)) ? 'placeholder' : ''}`}
                type={fieldType}
                data-type={type}
                placeholder={placeholder ? placeholder : ''}
                maxLength={maxLength !== 'none' ? (maxLength || max) : undefined}
                data-dropdown={dropdown ? true : false}
                data-form-type='other'
                data-max-length={maxLength !== 'none' ? (maxLength || max) : 'none'}
                disabled={disabled}
                autoComplete='off'
                autoCapitalize={type === 'allCaps' ? 'characters' : type=== 'firstCap' ? 'sentence' : ''}
                tabIndex={(disabled || classes?.includes('display-only') || masked || noPermission) ? -1 : 0}
                value={dropdown ? ifElse(dropdownDisplays?.[id]) : ifElse(inputValues?.[id])}
                onKeyDown={onKeyDown}
                onChange={(event)=>{onChange(event)}}
                onFocus={onFocus}
                onBlur={onBlur}/>
            {(fieldType === 'password') && !noPermission && <div className='input-password-reveal-button' onClick={onPasswordReveal}><Icon icon='eye' classes='clickable'/></div>}
            {(fieldType === 'password-revealed') && !noPermission && <div className='input-password-reveal-button' onClick={onPasswordHide}><Icon icon='eye-slash' classes='clickable'/></div>}
            <div className={`input-error-message ${(inputErrors?.[id]) ? 'show' : ''}`}>{inputErrors?.[id]}</div>
        </div>
    )
}

export default Input;