import { Navigate } from 'react-router-dom';
import { isProduction } from 'shared/utils';
import useAuth from 'hooks/useAuth';
import useUtils from 'hooks/useUtils';

const PermissionsCheck = ({path, children}) => {
    const { getAuth } = useAuth();
    const { canView } = useUtils();
    const token = getAuth();
    if (token) {
        if (!token.accessToken) {
            return <Navigate to='/login' replace />;
        } 
        if (canView(path) && !(path === '/delete-accounts' && isProduction())) {
            return children;
        } 
        return <Navigate to='/unauthorized' replace />;
    } else {
        return <Navigate to='/login' replace />;
    }
}

export default PermissionsCheck;
