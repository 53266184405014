import { text as t } from 'shared/text';

export const permissionsLayout = {
    page: t.permissions,
    subPage: t.roles,

    company: t.company,
    role: t.role,
    user: t.user,
    isApplyToRole: t.applyToRole,
    view: t.view,
    edit: t.edit,
    create: t.create,
    delete: t.delete,

    checkboxes: {
        firstHeader: t.view,
        secondHeader: t.edit,
        actionDisplay: t.permissionsOverride,
        company: t.company,
        users: t.users,
        locations: t.locations,
        inventory: t.inventory,
        collections: t.collections,
        serviceEvents: t.serviceEvents,
        deposits: t.deposits,
        reports: t.reports,
        activations: t.activations,
        settings: t.settings,
        messages: t.messages,
        dashboard: t.dashboards,
        devices:  t.devices
    }
}
