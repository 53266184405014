import { deepCopy, getNested, 
         isValid, toCurrency, 
         toCurrencyDecimal, 
         toCurrencyWhole} from 'components/shared/componentUtils';
import {text as t} from 'shared/text';

export const addCellClasses = (prop, layout, content, key) => {
    const sizes = layout.sizes;
    const format = layout.formats?.[prop];
    const hide = layout.hideColumns;
    const tooltips = layout.tooltips;
    let classes = layout.layouts?.[prop] ? layout.layouts[prop] : '';
    if (content === t.nullVal) { classes += (' placeholder') }
    if (layout.hideSortArrows?.[prop]) { classes += ' hide-sort' }
    if (format === 'currency' || format === 'currency-decimal' || format === 'currency-whole') {
        if (typeof content === 'string' && content.includes('-')) {classes += (' cell-negative')};
    }
    if (key) {
        if (sizes?.[key][prop]) { classes += ' ' + sizes[key][prop] };
        if (hide?.[key][prop]) { classes += (' hide-cell-' + hide[key][prop]) }
        if (tooltips?.[key][prop]) { classes += (' tooltip-cell' + tooltips[key][prop]) }

    } else {
        if (sizes?.[prop]) { classes += ' ' + sizes[prop] };
        if (hide?.[prop]) { classes += (' hide-cell-' + hide[prop]) }
        if (tooltips?.[prop]) { classes += (' tooltip-cell') }
    }
    return classes;
}

export const addLinkProps = (value, props, data) => {
    const length = props.length;
    for (let i = 0; i < length; i++) {
        value = value + '/' + getNested(props[i], data);
    }
    return value;
}

export const buildSpacer = (size, type, key) => {
    return <div key={key} className={`${type === 'header' ? 'table-header-cell' : 'table-cell space'} ${size}`}/>
}

export const clearSelected = (id) => {
    const old = document.getElementById(id)?.getElementsByClassName('selected')?.[0];
    if (old) { old.classList.remove('selected') }
}

export const formatContent = (content, prop, layout, filter) => {
    const country = layout.country;
    const formats = layout.formats;
    if (!isValid(content)) {return t.nullVal};
    if (typeof content === 'boolean') {
        content = content ? 'true' : 'false';
    }
    const type = formats?.[prop];
    const length = content?.length;
    switch (type) {
        case 'billType':
            return '$' + content;
        case 'currency':
            return toCurrency(content, country);
        case 'currency-decimal':
            return toCurrencyDecimal(content, country);
        case 'currency-whole':
            return toCurrencyWhole(content, country);
        case 'frequency' :
            return isValid(content) ?  `1 in ${content.toFixed(2)}`: '';
        case 'icon':
            if (filter) {
                return content;
            } else {
                const icon = formats[prop + 'Icon'];
                return <i className={icon ? icon : content}></i>
            }

        case 'indicator':
            if (content === 'true'){
                return <div className='table-indicator on'></div>
            } else {
                return <div className='table-indicator'></div>
            }
        case 'lastFour':
            return '*' + content.slice(length - 4, length);
        case 'number':
            return content.toLocaleString('en-US');
        case 'percentage' :
            return isValid(content) ? content.toFixed(2) + '%': '';
        case 'standard-date':
            const date = new Date(content);
            const options = {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric',
                hour: '2-digit',
                minute: '2-digit',
                hour12: true
            };
            return date.toLocaleString('en-US', options);
        case 'transactionType':
            return getTransactionType(content);
        case 'hidden-reference':
            return showNullVal(content) || content === 0 ? t.nullVal : content;
        default:
            return showNullVal(content) ? t.nullVal : content
    }
}

const getTransactionType = (content) => {
    switch (content) {
        case 0:
            return t.notSet
        case 1:
            return t.purchase
        case 2:
            return t.refund
        case 3:
            return t.releaseEscrow
        case 4:
            return t.noPurchaseNecessary
        case 5:
            return t.redemption;
        case 6:
            return t.promo;
        default:
            return content
    }
}

export const getDisplayedRows = (id, tableStates, displayData) => {
    const states = tableStates[id];
    const rowCount = states.rowCount;
    const currentPage = states.currentPage[0];
    const rows = displayData.slice(( rowCount * (currentPage-1)), (rowCount * currentPage));
    return rows;
}

export const getMax = (prop, data) => {
    const length = data.length;
    let count = 0;
    for (let i = 0; i < length; i++) {
        const itemCount = data[i][prop].length;
        if (itemCount > count) { count = itemCount };
    }
    return count;
}

export const createInfoPack = (data, filterData, layout) => {
    const defaultSort = layout.defaultSort;
    const sortProp = defaultSort ? defaultSort.prop : 'id';
    const sortDirection = defaultSort ? defaultSort.desc : true;
    const isoData =  [...sortList(deepCopy(data), sortProp, sortDirection)]
    const isoFilterData = [...sortList(deepCopy(filterData), sortProp, sortDirection)]
    return {
        data: isoData,
        filterData: isoFilterData
    }
}

export const runFilters = (id, data, filterData, layout, tableStates) => {
    let info = createInfoPack(data, filterData, layout);
    const filters = tableStates?.[id]?.filters;
    const input = filters?.inputValue;
    const orderSort = filters?.orderSort;
    input?.value && (info = filterByInput(info, input.value, layout));
    orderSort && (info = orderTableData(info, layout, orderSort));
    return info.data;
}

const filterByInput = (info, string) => {
    if (!string || string.length === 0) { return info};
    string = string.trim();
    const newInfo = {
        data: [],
        filterData: [],
    }
    const length = info.filterData.length;
    for (let i = 0; i < length; i++) {
        const item = info.filterData[i];
        const keys = Object.keys(info.filterData?.[0]).filter(key => key !== 'index');
        const itemLength = keys.length;
        for (let j = 0; j < itemLength; j++) {
            const key = keys[j];
            const value = item[key];
            if (isValid(value) && foundMatch(string, value)) {
                newInfo.data.push(info.data[i])
                newInfo.filterData.push(item);
                break;
            } else {
                continue;
            }
        }
    }
    return newInfo;
}

const orderTableData = (info, layout, orderSort) => {
    const prop = orderSort.prop;
    const value = orderSort.value;
    const defaultSort = layout.defaultSort;
    if (!value) {
        if (defaultSort){
            return orderInfo(info, defaultSort.prop, defaultSort.desc)
        } else {
            return orderInfo(info, 'id', false)
        }
    } else if (value === 'arrow-down') {
        return orderInfo(info, prop, true);
    } else if (value === 'arrow-up') {
        return orderInfo(info, prop, false);
    }
}

const foundMatch = (string, value) => {
    if (value.toString().toUpperCase().includes(string.toUpperCase())) {
        return true;
    } else {
        return false;
    }
}

const orderInfo = (info, prop, desc) => {
    const length = info.filterData.length;
    if (length === 0) {return info};
    info.data = sortList(info.data, prop, desc);
    info.filterData = sortList(info.filterData, prop, desc);
    return info;
};


export const showNullVal = (value) => {
    if (value === '' || value === null || value === undefined ) {
        return true;
    } else {
        return false;
    }
}

export const sortList = (array, prop, desc) => {
    if (!array || array.length === 0) {return [];}

    const newArray = [...array];

    const compareString = (a, b) => {
        const aValue = isValid(a[prop]) ? a[prop].toString().trimStart() : '';
        const bValue = isValid(b[prop]) ? b[prop].toString().trimStart() : '';
        if (isValid(bValue)) {
            return bValue.localeCompare(aValue);
        } else {
            return isValid(aValue) ? -1 : 0;
        }
    };

    const compareStringLabel = (a, b) => {
        const aValueLabel = isValid(a[prop]?.label) ? a[prop].label.trimStart() : '';
        const bValueLabel = isValid(b[prop]?.label) ? b[prop].label.trimStart() : '';
        if (isValid(bValueLabel)) {
            return bValueLabel.localeCompare(aValueLabel);
        } else {
            return isValid(aValueLabel) ? -1 : 0;
        }
    };

    const compareNumber = (a, b) => a[prop] - b[prop];

    if (desc) {
        if (typeof array?.[0]?.[prop] === 'number') {
            return newArray.sort((a, b) => compareNumber(b, a));
        } else if (typeof array?.[0]?.[prop] === 'boolean') {
            return newArray.sort((a, b) => a[prop] < b[prop] ? -1 : 0);
        } else if (typeof array[0][prop]?.label === 'string') {
            return newArray.sort((a, b) => compareStringLabel(a, b));
        } else {
            return newArray.sort((a, b) => compareString(a, b));
        }
    } else {
        if (typeof array?.[0]?.[prop] === 'number') {
            return newArray.sort((a, b) => compareNumber(a, b));
        } else if (typeof array?.[0]?.[prop] === 'boolean') {
            return newArray.sort((a, b) => a[prop] > b[prop] ? -1 : 0);
        } else if (typeof array?.[0]?.[prop]?.label === 'string') {
            return newArray.sort((a, b) => compareStringLabel(b, a));
        } else {
            return newArray.sort((a, b) => compareString(b, a));
        }
    }
};

export const updateFilters = (newStates, setter) => {
    setter(newStates);
}
