import {text as t} from 'shared/text';

export const locationDevicesLayout = {
    id: 'locationDevices',
    subPage: t.devices,

    tableHeaders: {
        id: t.edit,
        name: t.name,
        type: t.type,
        cabinetSerial: t.cabinetSerial,
        boardSerial: t.boardSerial,
        refSerial: t.refSerial,
        trueRouteId: t.trueRouteId,
        installDate: t.installDate
    },

    sizes: {
        id: 'mid',
        name: 'small',
        type: 'mid',
        cabinetSerial: 'large',
        boardSerial: 'large',
        refSerial: 'large',
        trueRouteId: 'large',
        installDate: 'large',
    },

    layouts: {
        name: 'text-left',
        type: 'text-left',
        cabinetSerial: 'text-left',
        boardSerial: 'text-left',
        refSerial: 'text-left',
        trueRouteId: 'text-left',
        installDate: 'text-left',
    },

    navigation: {
        id: {
            root: 'device',
            value: 'id',
            label: 'icon',
        }
    },

    hideSortArrows: {
        id: true
    },

    formats: {
        installDate: 'standard-date',
        id: 'icon',
        idIcon: 'bi-pencil-square',
    }
}
