import { useContext } from 'react';
import { deepCopy, extractNums,
         getFormProps, isCustomFormat, 
         isValid, toCurrency, 
         toCurrencyDecimal, toPhone, 
         toGRCPin, toZip,
         getLabelFromOptions, validateEmail } from 'components/shared/componentUtils';
import { componentText as t } from 'components/shared/componentData';
import InputsContext from './InputsContext';

const intMax = 15; // max int that JS can track is 9,007,199,254,740,991, or 9 pentillion at 16 chars in length. 
                   // Rather than deal with actual values, I set 15 as the max length. With a val of 999 quadrillion,
                   // I think a length of 15 should be sufficient for numbers.

const useInputs = () => {
    const { currentInput, setCurrentInput,
            inputValues, setInputValues,
            inputErrors, setInputErrors,
            dropdownOriginals, setDropdownOriginals, 
            dropdownDisplays, setDropdownDisplays,
            touchscreen, setTouchscreen,
            touchScreenState, setTouchScreenState,
            sliderClosed, setSliderClosed,
    } = useContext(InputsContext);
    const changeCurrentInput = (a) => {setCurrentInput(a)};
    const changeInputValues = (a) => {setInputValues(a)};
    const changeInputErrors = (a) => {setInputErrors(a)};
    const changeDropdownOriginals = (a) => {setDropdownOriginals(a)};
    const changeDropdownDisplays = (a) => {setDropdownDisplays(a)};
    const changeTouchscreen = (a) => {setTouchscreen(a)};
    const changeTouchScreenState = (a) => {setTouchScreenState(a)};
    const changeSliderClosed = (a) => {setSliderClosed(a)};

    // Add Functions
    const updateInput = (id, val, dropdown) => {
        dropdown ? updateDropdownDisplays(id, val) : updateValues(id, val)
    }

    const updateValues = (id, val) => {
        const newValues = deepCopy(inputValues);
        newValues[id] = val;
        changeInputValues(newValues);
    }

    const updateDropdown = (id, val, label) => {
        updateValues(id, val)
        updateDropdownOriginals(id, label);
        updateDropdownDisplays(id, label);
    }

    const updateDropdownOriginals = (id, val) => {
        const newOriginals = deepCopy(dropdownOriginals);
        newOriginals[id] = val;
        changeDropdownOriginals(newOriginals);
    }

    const updateDropdownDisplays = (id, val) => {
        const newDisplays = deepCopy(dropdownDisplays);
        newDisplays[id] = val;
        changeDropdownDisplays(newDisplays);
    }

    const updateInputErrors = (id, val) => {
        const newError = deepCopy(inputErrors);
        newError[id] = val;
        changeInputErrors(newError);
    };

    const clearInput = (id, dropdown) => {
        clearError(id);
        dropdown ? updateDropdownDisplays(id, '') : updateValues(id, '')
    }

    const clearAllInputs = () => {
        changeCurrentInput('');
        changeInputValues({});
        changeInputErrors({});
        changeDropdownOriginals({});
        changeDropdownDisplays({});
        changeSliderClosed(true);
    }

    const clearError = (id) => {
        const newErrors = deepCopy(inputErrors);
        newErrors[id] = '';
        changeInputErrors(newErrors);
    }

    // Mass Change Functions
    const updateInputData = (data, dropdowns, listsArray) => {
        changeInputErrors({});
        const values = {};
        const originals = {};
        const keys = Object.keys(data);
        keys.forEach((prop) => {
            const rawVal = data[prop]; 
            const dataset = document.getElementById(prop)?.dataset
            const type = dataset?.type;
            const dropdown = dataset?.dropdown;
            let value;
            if (dropdown === 'true') {
                const info = dropdowns[prop];
                if (!rawVal && !info?.nullable) {
                    originals[prop] = '';
                } else {
                    value = rawVal;
                    originals[prop] = getLabelFromOptions(rawVal, listsArray[info.list], info.nullable, info.valueProp, info.labelProp, info.appendProp);   
                }
            } else {
                value = getCleanInput(rawVal, type);
            }
            values[prop] = value;
        });
        changeInputValues(values);
        dropdowns && changeDropdownOriginals(originals);
        dropdowns && changeDropdownDisplays(originals)
        return true;
    }

    const updateNestedInputData = (data, dropdowns, listsArray, arrayInfo) => {
        changeInputErrors({});
        const values = {};
        const originals = {};
        const props = getFormProps(data, arrayInfo);
        props.forEach((item)=>{
            const {id, section, index, line} = item;
            const i = isValid(index);
            let rawVal;
            if (section && i && line) {
                rawVal = data[section][index][line]
            } else if (section && i && !line) {
                rawVal = data[section][index]
            } else if (section && !i && line) {
                rawVal = data[section][line]
            } else {
                rawVal = data[id];
            }
            const dataset = document.getElementById(id)?.dataset
            const type = dataset?.type;
            const dropdown = dataset?.dropdown;
            let value;
            if (dropdown === 'true') {
                const info = dropdowns[id];
                if (!rawVal && !info?.nullable) {
                    originals[id] = '';
                } else {
                    value = rawVal;
                    originals[id] = getLabelFromOptions(value, listsArray[info.list], info.nullable, info.valueProp, info.labelProp, info.appendProp);
                }
            } else {
                value = getCleanInput(rawVal, type);
            }
            values[id] = value;
        })
        changeInputValues(values);
        dropdowns && changeDropdownOriginals(originals);
        dropdowns && changeDropdownDisplays(originals);
        return true;
    }

    const getCleanInput = (val, type) => {
        switch (type) {
            case 'currency':
            case 'currency-decimal':
            case 'number':
                return extractNums(val).slice(0,intMax);
            case 'grc-pin':
                return extractNums(val).slice(0,12);
            case 'zip':
                return extractNums(val);
            case 'phone':
                val = val.startsWith('+1') ? val.slice(2) : val;
                return extractNums(val).slice(0,10);
            case 'allCaps': 
                return val.toUpperCase();
            case 'firstCap':
                return val.charAt(0).toUpperCase() + val.slice(1);
            default:
                return isValid(val) ? val : null; 
        }
    }

    const reNestedData = (data) => {
        const keys = Object.keys(inputValues);
        keys.forEach((key) => {
            const el = document.getElementById(key);
            const value = (el && el.autocapitalize === 'characters') ? inputValues[key].toUpperCase() : inputValues[key];
            const matches = key.match(/-/g); 
            const count = matches ? matches.length : 0; // Count them
            if (count === 2) {
                const [section, index, line] = key.split('-');
                if (section && !data[section]) {
                    data[section] = {};
                }
                data[section][index][line] = value;
            }
            else if (count === 1) {
                const [section, line] = key.split('-');
                if (section && !data[section]) {
                    data[section] = {};
                }
                data[section][line] = value;
            } else {
                data[key] = value;
            }
        });
        return data;
    }

    const validateForm = (validators) => {
        if (!validators) {return null}
        const errorList = {};
        validators.forEach((item, i) => {
            const id = item.id;
            const checks = item.checks;
            const value = inputValues[id];
            const minLength = item.minLength;
            if (checks.includes('required') && !value) {
                errorList[id] = t.errorRequired;
            } else if (minLength && value.length < minLength) {
                errorList[id] = t.errorMinLength1 + minLength + t.errorMinLength2;
            } else {
                if (checks.includes('email') && value && !validateEmail(value)) {
                    errorList[id] = t.errorEmail;
                } else if (checks.includes('phone') && value && value.length !== 10 ) {
                    errorList[id] = t.errorPhone;
                } else if (checks.includes('zip') && value && (value.length !== 5 && value.length !== 9)) {
                    errorList[id] = t.errorZip;
                } else if (checks.some(str => str.includes('min'))){
                    const minCheck = checks[checks.findIndex(str => str.includes('min'))];
                    const parts = minCheck.split(':');
                    const minVal = parseInt(parts[1]);
                    if (!(value > minVal)) {
                        errorList[id] = t.errorMin + minVal;
                    }
                }
            }
        })
        return Object.keys(errorList).length === 0 ? null : errorList;
    }

    const getInputDisplay = (val, type) => {
        switch (type) {
            case 'allCaps': 
                return val.toUpperCase();
            case 'firstCap':
                return val.charAt(0).toUpperCase() + val.slice(1);
            case 'currency':
                return toCurrency(val)
            case 'currency-decimal':
                return toCurrencyDecimal(val);
            case 'phone':
                return toPhone(val);
            case 'grc-pin':
                return toGRCPin(val);
            case 'zip':
                return toZip(val);
            default:
                return val; 
        } 
    }

    const newData = (id, val, data) => {
        const newData = deepCopy(data);
        newData[id] = val;
        return newData;
    }

    // Touch Screen Functionality
    const handleTouchscreen = ()=>{           
        const closeTouchScreens = (event) => {
            if (!touchscreen) {return};
            let target = event.target;
            const tag = target.tagName;
            if (tag === 'path' || tag === 'svg' || tag === 'I') {
                target = target.closest('div');
            }
            const isTouchscreen = target.dataset.touchscreen;
            if (!isTouchscreen && target.tagName !== 'INPUT') {
                changeSliderClosed(true);
            }
        }
    
        window.addEventListener('touchstart', closeTouchScreens);
        return () => {
            window.removeEventListener('touchstart', closeTouchScreens);
        };
    }; 

    return { 
        currentInput, changeCurrentInput,
        inputValues, changeInputValues, 
        dropdownOriginals, changeDropdownOriginals, 
        dropdownDisplays, changeDropdownDisplays, 
        inputErrors, changeInputErrors,
        touchscreen, changeTouchscreen,
        touchScreenState, changeTouchScreenState,
        sliderClosed, changeSliderClosed,
        updateInput, updateValues, updateDropdown,
        updateDropdownOriginals, updateDropdownDisplays,
        updateInputErrors, newData,
        isCustomFormat, reNestedData, 
        clearError, clearInput,
        clearAllInputs, getInputDisplay,
        updateInputData, updateNestedInputData,
        validateForm, handleTouchscreen,
    }
}

export default useInputs;