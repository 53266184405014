import { text as t } from 'shared/text'

export const apiLogDetailsLayout = {
    id: 'apiLogDetails',

    tableHeaders: {
        id: t.id,
        message: t.message,
        logClass: t.logClass,
        detailType: t.detailType
    },

    sizes: {
        id: 'small',
        message: 'large',
        logClass: 'mid',
        detailType: 'mid'
    },

    layouts: {
        message: 'text-left',
        logClass: 'text-left',
    },

    tooltips: {
        message: {prop: 'message'}
    },

    rowCount: 100
}