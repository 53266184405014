import { text as t } from 'shared/text'

export const locationTransactionsLayout = {
    tableHeaders: {
        id: t.transactionId,
        terminalId: t.terminalId,
        terminalType: t.terminalType,
        phoneNumber: t.phoneNumber,
        transactionType: t.transactionType,
        cashIn: t.cashIn,
        cashOut: t.cashOut, 
        net: t.netCash,
        createdOn: t.createdOn
    },

    sizes: {
        id: 'mid',
        terminalId: 'mid',
        terminalType: 'mid',
        phoneNumber: 'large',
        transactionType: 'large',
        cashIn: 'mid',
        cashOut: 'mid', 
        net: 'mid',
        createdOn: 'large'
    },
    
    layouts: {
        id: 'text-left',
        terminalId: 'text-left',
        terminalType: 'text-left',
        phoneNumber: 'text-left',
        transactionType: 'text-left',
        cashIn: 'blank text-right',
        cashOut: 'blank text-right', 
        net: 'blank text-right',
        createdOn: 'text-left'
    },

    formats: {
        phoneNumber: 'lastFour',
        cashIn: 'currency',
        cashOut: 'currency', 
        net: 'currency',
        createdOn: 'standard-date'
    },

    navigation: {
        terminalId: {
            root: 'device',
            label: 'terminalId',
            value: 'terminalId'
        }
    },

    hideSortArrows: {
        net: true
    },

    subtotals: {
        groupBy: 'all',
        fields: {
            id: 'header',
            cashIn: 'total',
            cashOut: 'total',
            net: {
                first: {column:'cashIn', type: 'total'},
                 calc: 'add',
                 second: {column:'cashOut', type: 'total'}
             },
        }, 
        layouts: {
            id: 'text-left',
            cashIn: 'text-right',
            cashOut: 'text-right',
            net: 'text-right'
        },
        formats: {
            cashIn: 'currency',
            cashOut: 'currency',
            net: 'currency'
        }
    }
}
