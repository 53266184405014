import { text as t } from 'shared/text';

export const devicesTabsList = {
    all: { label: t.all, path: '/devices', rank: 'all'},
    primePay: { label: t.primePay, path: '/devices/primepay', rank: 'all'},
    sweepsKiosk: { label: t.grcKiosks, path: '/devices/grc-kiosks', rank: 'all'},
    gameRoomPOS: { label: t.gameroomPOS, path: '/devices/gameroom-pos', rank: 'all'},
    grcPointOfSale: { label: t.grcPOS, path: '/devices/grc-pos', rank: 'all'},
    connectedGames: { label: t.games, path: '/devices/games', rank: 'all'},
    bingoKiosk: { label: t.bingo, path: '/devices/bingo', rank: 'all'},
    monitor: { label: t.monitors, path: '/devices/monitors', rank: 'admin'}
}
