import { text as t } from 'shared/text'

export const manifestsLayouts = {
    id: 'manifests',

    tableHeaders: {
        id: t.edit,
        terminalId: t.terminalId,
        locationId: t.locationId,
        userId: t.userId,
        companyId: t.companyId,
        terminalType: t.terminalType,
        terminalSubClass: t. terminalSubtype,
        targetVersion: t.version,
        targetDateTime: t.targetDateTime,
        updateSource: t.source,
        updateSourceType: t.sourceTypes,
        isNewInstall: t.isNewInstall,
    },

    sizes: {
        id: 'icon',
        terminalId: 'mid',
        locationId: 'mid',
        userId: 'mid',
        companyId: 'mid',
        terminalType: 'mid',
        terminalSubClass: 'mid',
        targetVersion:'mid',
        targetDateTime: 'large',
        updateSource: 'mid',
        updateSourceType: 'mid',
        isNewInstall: 'large'
    },

    navigation: {
        id: {
            root: 'manifest',
            label: 'id',
            value: 'id',
        },
        userId: {
            root: 'user',
            label: 'userId',
            value: 'userId'
        },
        locationId: {
            root: 'location',
            label: 'locationId',
            value: 'locationId',
        },
        terminalId: {
            root: 'device',
            label: 'terminalId',
            value: 'terminalId',
        }
    },

    formats: {
        id: 'icon',
        idIcon: 'bi-pencil-square',
        targetDateTime: 'standard-date'
    },

    hideSortArrows: {
        id: true
    }
}
