import './link.scss';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { toStandardDate } from 'components/shared/componentUtils';
import { text as t } from 'shared/text';
import useComponents from 'components/hooks/Components/useComponents';
import useProfile from 'hooks/useProfile';
import useUtils from 'hooks/useUtils';

const Link = ({ link, label, prop, rowData, children }) => {
    const { id } = useParams();
    const { navTo } = useComponents();
    const { callAPIGetBlob, updateNotice } = useUtils();
    const { setHeader } = useProfile();
    const [ loading, setLoading ] = useState(false);

    const onLinkClick = (event, prop) => {
        event.preventDefault();
        if (prop === 'download') {
            setLoading(true);
            downloadLogFile(event, link);
        } else {
            let string = ''
            if (link.includes('device') || 
                link.includes('manifest') || 
                link.includes('source')) {
                // skip - let the page handle it
            }else {
                string = label.toString()
            }
            setHeader(string);
            navTo(link);
        }
    }

    const downloadLogFile = (event, id) => {
        const params = {
            responseType: 'blob'
        }
        const props = {
            id: id,
            event: event,
            setLoading: setLoading
        }
        callAPIGetBlob('downloadLogFile', downloadLogFileCallback, params, props);
    }

    const downloadLogFileCallback = (data, props) => {
        props.targetClass = '.link-target';
        if (data.type !== 'application/x-gtar' || (typeof data === 'string' && data.toLocaleUpperCase().includes('NOT FOUND'))) {
            updateNotice(data, props);
        } else {
            updateNotice({ isSuccessful: true }, props);
            const url = window.URL.createObjectURL(new Blob([data]));
            const link = document.createElement('a');
            link.href = url;
            const filename = t.logs + '-' + id + '-' + toStandardDate(rowData.createdOn) + '.tar.gz';
            link.setAttribute('download', filename);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    return (
        <div className='link-container'>
            <a href={link} className={`link-target ${loading ? 'disabled' : ''}`} onClick={(event) => { onLinkClick(event, prop) }}>
                {loading ? <span className='spinner'/> : children}
            </a>
        </div>
    )
}

export default Link;